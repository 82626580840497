import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState, AppThunk } from '../../app/store';
import { datastreamRequestData } from '../../types'

export interface RequestQueueState {
  queuedDatastreams: datastreamRequestData[],
  metricsData: any,
  queryUserDB: boolean,
  selectedQueryUser: string|null,
  queryUserOptions: any,
  dsArray: string[],
  dsMap: any
}

const initialState:RequestQueueState = {
    queuedDatastreams: [],
    metricsData: [],
    queryUserDB: false,
    selectedQueryUser: null,
    queryUserOptions: [
      { value: 'aking', label: 'aking' },
      { value: 'sockol', label: 'sockol' },
      { value: 'kehoe', label: 'kehoe' },
      { value: 'godine', label: 'godine' },
    ],
    dsArray: [],
    dsMap: {}
};

export const requestQueueSlice = createSlice({
  name: 'requestQueue',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setQueuedDatastreams: (state, action: PayloadAction<any>) => {
      state.queuedDatastreams = action.payload
    },
    setMetricsData: (state, action: PayloadAction<any>) => {
      state.metricsData = action.payload
    },
    setQueryUserDB: (state, action: PayloadAction<any>) => {
      state.queryUserDB = action.payload
    },
    setSelectedQueryUser: (state, action: PayloadAction<any>) => {
      state.selectedQueryUser = action.payload
    },
    setDsMap: (state, action: PayloadAction<any>) => {
      state.dsMap = action.payload
    },
    setDsArray: (state, action: PayloadAction<any>) => {
      state.dsArray = action.payload
    }
  }
});

export const { setQueuedDatastreams, setMetricsData, setQueryUserDB, setSelectedQueryUser, setDsMap, setDsArray } = requestQueueSlice.actions;

export const selectQueuedDatastreams = (state: RootState) => state.requestQueue.queuedDatastreams;
export const selectMetricsData = (state: RootState) => state.requestQueue.metricsData;
export const selectQueryUserDB = (state: RootState) => state.requestQueue.queryUserDB;
export const selectSelectedQueryUser = (state: RootState) => state.requestQueue.selectedQueryUser;
export const selectQueryUserOptions = (state: RootState) => state.requestQueue.queryUserOptions;
export const selectDsMap = (state: RootState) => state.requestQueue.dsMap;
export const selectDsArray = (state: RootState) => state.requestQueue.dsArray;

export default requestQueueSlice.reducer; 