import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import styles from './LoadingOverlay.module.css';

export const LoadingOverlay = () => {

  return(
    <div className={styles.LoadingOverlayBG}>
      <div className={styles.LoadingOverlayContainer}>
        <p className={styles.LoadingOverlayLabel}>Loading...</p>
        <FontAwesomeIcon className={`${styles.LoadingOverlaySpinner} fa-spin`} icon={faSpinner} size="3x"/>
      </div>
    </div>
  )
}