import React, { useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import { default as ReactSelect } from "react-select";
import CreatableSelect from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { isAfter, isBefore } from 'date-fns'

import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { selectCurrentUser } from '../menuBar/authSlice';
import { selectDsArray, selectQueuedDatastreams, selectSelectedQueryUser, selectQueryUserDB } from '../requestQueue/requestQueueSlice';
import {
  selectUserFavorites,
  setUserFavorites
} from '../userDashboard/userDashboardSlice';


import styles from './FavoritesModal.module.css';

interface FavoritesModalProps {
    isShowingModal: boolean
    onClose: any
    saveFavorite: any
}

export const FavoritesModal = (props:FavoritesModalProps) => {
    const dispatch = useAppDispatch();

    const dsArray = useAppSelector(selectDsArray)
    const currentUser = useAppSelector(selectCurrentUser);
    const queryUserDB = useAppSelector(selectQueryUserDB)
    const selectedQueryUser = useAppSelector(selectSelectedQueryUser)
    const userFavorites = useAppSelector(selectUserFavorites)

    const dsOptions = (dsArray === null || dsArray === undefined) ? [] : dsArray!.map((s, i) => ({ value: s, label: s }))

    const queuedDatastreams = useAppSelector(selectQueuedDatastreams)
    // const defaultSelectedDatastreams = (queuedDatastreams === null || queuedDatastreams === undefined) ? [] : queuedDatastreams!.map((s, i) => ({ value: s.name, label: s.name }))
    
    const [selectedStartDate, setSelectedStartDate] = useState<null|Date>(null)
    const [selectedEndDate, setSelectedEndDate] = useState<null|Date>(null)
    const [selectedDateOption, setSelectedDateOption] = useState<string>("static")
    // const [defaultSelectedDatastreams, setDefaultSelectedDatastreams] = useState<any[]>([])
    const [selectedDatastreams, setSelectedDatastreams] = useState<any[]>([])
    const [numDays, setNumDays] = useState<number>(7)
    const [message, setMessage] = useState<string>("")
    const [messageColor, setMessageColor] = useState<string>("green")

    useEffect(() => {

      if(queuedDatastreams.length > 0) {
        let tempSdate = moment(queuedDatastreams[0].sdate, "YYYYMMDD").toDate()
        let tempEdate = moment(queuedDatastreams[0].edate, "YYYYMMDD").toDate()

        setSelectedStartDate(tempSdate)
        setSelectedEndDate(tempEdate)

        let tempSelectedDatastreams = queuedDatastreams.map((s, i) => ({ value: s.name, label: s.name }))
        setSelectedDatastreams(tempSelectedDatastreams)
      }
    }, [queuedDatastreams]);


    // useEffect(() => {
    //   setSelectedDatastreams(defaultSelectedDatastreams)
    // }, [defaultSelectedDatastreams])

    const handleDateChange = (startDate:any, endDate:any) => {

        startDate = startDate || selectedStartDate
        endDate = endDate || selectedEndDate

        if (isBefore(endDate, selectedStartDate!)) {
          startDate = endDate
        } else if (isAfter(startDate, selectedEndDate!)) {
          endDate = startDate
        }

        setSelectedStartDate(startDate)
        setSelectedEndDate(endDate)
    }

    const handleChangeStart = (startDate: any) => {
      handleDateChange(startDate,null)
    }
    const handleChangeEnd = (endDate: any) =>  {
      handleDateChange(null,endDate)
    }
    const handleDateTypeChange = (changeEvent:any) => {
     setSelectedDateOption(changeEvent.target.value)
    };
    const handleNumDaysChange = (changeEvent:any) => {
      setNumDays(changeEvent.target.value)
    }
    const handleDsChange = (selectedOptions:any, action:any) => {
      setSelectedDatastreams(selectedOptions)
    }
    const handleSaveFavorite = () => {

      let queryUser = "dqmgr"
      if (queryUserDB && selectedQueryUser !== null) {
          queryUser = selectedQueryUser
      }

      let favObj = {
        "datastreams": selectedDatastreams.map((s, i) => (s.value)),
        "sdate": selectedDateOption === "static" ? moment(selectedStartDate).format('YYYYMMDD') : "$latest-" + numDays.toString(),
        "edate": selectedDateOption === "static" ? moment(selectedEndDate).format('YYYYMMDD') : "$latest",
        "query_user": queryUser,
      }
      console.log(favObj)

      props.saveFavorite(currentUser!.armID, favObj, (res:any) => {
        console.log(res)
        if('error' in res.data.result){
          console.log("ERROR")
          setMessageColor("red")
          setMessage(res.data.result.error)
        } else {
          console.log("SUCCESS")
          dispatch(setUserFavorites([...userFavorites, favObj]))
          setMessageColor("green")
          setMessage("favorite added successfully.")
        }
        
      })
    }  

    const handleModalClose = () => {
      if(queuedDatastreams.length > 0) {
        let tempSdate = moment(queuedDatastreams[0].sdate, "YYYYMMDD").toDate()
        let tempEdate = moment(queuedDatastreams[0].edate, "YYYYMMDD").toDate()

        setSelectedStartDate(tempSdate)
        setSelectedEndDate(tempEdate)

        let tempSelectedDatastreams = queuedDatastreams.map((s, i) => ({ value: s.name, label: s.name }))
        setSelectedDatastreams(tempSelectedDatastreams)
      }

      props.onClose()
    }


    return(
      <Modal open={props.isShowingModal} onClose={handleModalClose} classNames={{ overlay: styles.ModalOverlay, modal: styles.Modal, closeButton:styles.ModalCloseButton, closeIcon:styles.ModalCloseIcon}} center>
        <div style={{minWidth:320}}>
          <div >
            <h3 style={{margin:0, paddingBottom:20}}>Save to Favorites</h3>
            </div>
            <CreatableSelect
              isMulti
              // defaultValue={defaultSelectedDatastreams}
              value={selectedDatastreams}
              onChange={(selectedOptions, action) => {handleDsChange(selectedOptions, action)}} 
              options={dsOptions}
              placeholder={"Add Datastreams..."}
              noOptionsMessage={() => "Start typing to add a datastream \n(ex. sgpmetE13.b1)"}
              formatCreateLabel={(inputValue:string) => "add: " + inputValue}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />

            <div className={styles.DateTypeContainer}>
              <input onChange={handleDateTypeChange} checked={selectedDateOption === "static"} type="radio" id="static" name="date_type" value="static"/>
              <label htmlFor="static">Static Date Range</label>
              <input onChange={handleDateTypeChange} checked={selectedDateOption === "dynamic"} type="radio" id="dynamic" name="date_type" value="dynamic"/>
              <label htmlFor="dynamic">Dynamic Date Range</label>
            </div>

            { selectedDateOption === "static" ?
              <div className={styles.DateRangeContainer}>
                <div className={styles.DatePickerContainer}>
                  <DatePicker
                    dateFormat= "yyyy-MM-dd"
                    placeholderText="Start Date"
                    className={styles.DatePicker}
                    maxDate={new Date()}
                    selected={selectedStartDate}
                    selectsStart
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    onChange={handleChangeStart}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabledKeyboardNavigation
                    withPortal
                  />
                </div>
                <div className={styles.DatePickerContainer}>
                  <DatePicker
                    dateFormat= "yyyy-MM-dd"
                    placeholderText="End Date"
                    className={styles.DatePicker}
                    maxDate={new Date()}
                    selected={selectedEndDate}
                    selectsEnd
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    onChange={handleChangeEnd} 
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabledKeyboardNavigation
                    withPortal
                  />
                </div>
              </div>
              :
              <p>Latest <input type="number" id="quantity" name="quantity" min="1" max="60" value={numDays} onChange={handleNumDaysChange}/> days from current date.</p>
            }
            <p style={{color: messageColor}}>{message}</p>
            <button onClick={handleSaveFavorite} disabled={selectedDatastreams.length === 0 || ( selectedDateOption === "static" && (selectedStartDate === null || selectedEndDate === null))} className ={styles.ModalButton}>Save Favorite</button>
        </div>
      </Modal>
    )
}