import React, { useState, Component, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import {
  selectLoginModalIsOpen,
  setLoginModalIsOpen,
  selectIsAuthenticating,
  selectIsAuthenticated,
  selectAuthenticationMessage,
  selectCurrentUser
} from './authSlice';

import styles from './LoginModal.module.css';

function useOutsideAlerter(ref:any) {
	const dispatch = useAppDispatch();

	const closeModal = () => {
		dispatch(setLoginModalIsOpen(false))
	}

    useEffect(() => {
        function handleClickOutside(event:any) {
            if (ref.current && !ref.current.contains(event.target)) {
                closeModal()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

interface LoginModalProps {
  authenticateUser: any,
}

export function LoginModal(props:LoginModalProps) {

	const [username, setUsername] = useState<string>('')
	const [password, setPassword] = useState<string>('')

	const loginModalIsOpen = useAppSelector(selectLoginModalIsOpen);
	const isAuthenticating = useAppSelector(selectIsAuthenticating);
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const authenticationMessage = useAppSelector(selectAuthenticationMessage);
	const currentUser = useAppSelector(selectCurrentUser);

	const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

	const handleChange = (e:any) => {
		let value = e.target[e.target.type === "checkbox" ? "checked" : "value"]
    	let name = e.target.name;

    	if(name === "password") {
    		setPassword(value)
    	}
    	else {
    		setUsername(value)
    	}
	}

	const handleKeyPress = (e:any) => {
		var code = e.keyCode || e.which;
	    if(code === 13) { //13 is the enter keycode
	        props.authenticateUser(username, password)
	    } 
	}

	return(
		<div>
		{ loginModalIsOpen ?
			<div className={styles.modalBgOverlay}>
				<div  ref={wrapperRef} className={styles.modalLoginContainer}>
					<div className={styles.modalLoginHeader}>
						<p ><strong>Sign In</strong></p>
					</div>
					<div className={styles.modalLoginBody}>
						<input onKeyPress={handleKeyPress} name='username' onChange={handleChange} type='text' placeholder='Username'/>
						<input onKeyPress={handleKeyPress} name='password' onChange={handleChange} type='password' placeholder='Password'/>
						<button disabled={isAuthenticating} className={styles.modalLoginButton} onClick={() => props.authenticateUser(username, password)}>
							
							{ isAuthenticating ?
								<FontAwesomeIcon className={styles.modalLoginSpinner} icon={faSpinner} size="2x" />
							:
								'Login'
							}
						</button>
						<p style={{color:"red", textAlign:"center"}}>{authenticationMessage}</p>
						<p><a href='#'>Forgot Username or Password</a></p>
					</div>
				</div>
			</div>
			:
			null
		}
		</div>
	)
	
}