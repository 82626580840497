import React, { useState } from 'react';
import Modal from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faLongArrowAltLeft, faSpinner, faShareAlt, faClipboard } from '@fortawesome/free-solid-svg-icons'

import styles from './HelpFooter.module.css';


interface HelpFooterProps {
    sendHelpEmail: any
}

export const HelpFooter = (props:HelpFooterProps) => {

  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [sendIsDisabled, setSendIsDisabled] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Send');

  const showModal = () => {
    setIsShowingModal(true)
  }
  const handleClose = () => {
    setIsShowingModal(false)
    setEmail('')
    setComment('')
  }
  const handleEmailChange = (e:any) => {
    setEmail(e.target.value)
  }
  const handleCommentChange = (e:any) => {
    setComment(e.target.value)
  }

  const handleSendEmail = () => {
    setSendIsDisabled(true)

    props.sendHelpEmail(email, comment, (res:string) => {
      if (res === "success") {
        setButtonText('Help Request Sent')
        setTimeout(() => {
          handleClose()
          setSendIsDisabled(false)
          setButtonText('Send')
        }, 2000)
      } else {
        setButtonText('Error Sending Request')
        setTimeout(() => {
          handleClose()
          setSendIsDisabled(false)
          setButtonText('Send')
        }, 2000)
      }
    })
  }




  return(
    <div>
      <div className={styles.Footer}>
        <p>&copy; ARM DQ Office | <a onClick={showModal}>Need Help?</a></p>
      </div>
      <Modal open={isShowingModal} onClose={handleClose} classNames={{ overlay: styles.ModalOverlay, modal: styles.Modal, closeButton:styles.ModalCloseButton, closeIcon:styles.ModalCloseIcon}} center>
        <div style={{minWidth:250}}>
          <div >
            <h3 style={{margin:0, paddingBottom:20}}>DQ Explorer Help</h3>
          </div>
          <div>
            <p style={{margin:5}}><b>Email Address:</b></p>
            <input onChange={handleEmailChange}  placeholder="Enter your email..." value={email} className={styles.ModalInput}/>

            <br/>
            <br/>

            <p style={{margin:5}}><b>Description/Comment:</b></p>
            <textarea onChange={handleCommentChange} placeholder="Describe the issue or leave a comment..." style={{height:70}} value={comment} className={styles.ModalInput}/>

            <br/>
            <br/>

            <button disabled={sendIsDisabled} onClick={handleSendEmail} className={styles.ModalButton}>{buttonText}</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

