import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectQueuedDatastreams, 
    selectQueryUserDB, 
    selectSelectedQueryUser 
} from '../requestQueue/requestQueueSlice';

import {
  selectCurrentUser,
} from '../menuBar/authSlice';

import { FavoritesModal } from './FavoritesModal';

import styles from './FloatingFavoritesButton.module.css';


interface FloatingFavoritesButtonProps {
    saveFavorite: any
}

export const FloatingFavoritesButton = (props:FloatingFavoritesButtonProps) => {

    const [isShowingModal, setIsShowingModal] = useState<boolean>(false);

    const queuedDatastreams = useAppSelector(selectQueuedDatastreams)
    const currentUser = useAppSelector(selectCurrentUser);
    const queryUserDB = useAppSelector(selectQueryUserDB)
    const selectedQueryUser = useAppSelector(selectSelectedQueryUser)


    const handleFavoriteButtonClick = () => {
        showModal()
    }

    const showModal = () => {
      setIsShowingModal(true)
    }

    const handleClose = () => {
      setIsShowingModal(false)
    }

    return (
        <div>
          <div onClick={handleFavoriteButtonClick} className={styles.FloatingFavoritesButtonContainer}>
            <FontAwesomeIcon className={styles.FloatingFavoritesButton} icon={faHeart} />
          </div>
          <FavoritesModal saveFavorite={props.saveFavorite} isShowingModal={isShowingModal} onClose={handleClose} />
        </div>
    )
}

