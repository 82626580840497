import React, { useState, useEffect, Component, forwardRef } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Collapsible from 'react-collapsible';
// import { Tooltip, withTooltip } from 'react-tippy';
import Tippy, { TippyProps } from '@tippyjs/react';
import Modal from 'react-responsive-modal';
import Switch from "react-switch";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faImages, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import { parse } from 'date-fns'
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import styles from './MetricsDisplay.module.css';
import './MetricsDisplay.css'; // Have to import separate css file to style react-collapsible properly
import { selectMetricsData, selectQueuedDatastreams, selectDsMap } from '../requestQueue/requestQueueSlice';
import { FieldMetricsDisplay } from './FieldMetricsDisplay';
import { PlotDisplay } from '../plotDisplay/PlotDisplay';


import 'react-responsive-modal/styles.css';
import 'tippy.js/dist/tippy.css'; // optional
// import 'react-tippy/dist/tippy.css'


// Export own set of props (even if they are the same for now) to enable clients to be more future-proof
export type LazyTippyProps = TippyProps;

export const LazyTippy = (props: LazyTippyProps) => {
  const [mounted, setMounted] = React.useState(false);

  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  };

  const computedProps = { ...props };

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];

  if (props.render) {
    const render = props.render; // let TypeScript safely derive that render is not undefined
    computedProps.render = (...args) => (mounted ? render(...args) : '');
  } else {
    computedProps.content = mounted ? props.content : '';
  }

  return <Tippy {...computedProps} />;
};

interface MetricsDisplayProps {
    fetchPlots: any
    decomposeDatastream: any
    getWikiLink: any
    sendAlertEmail: any
    submitReprocessingJob: any
}

export function MetricsDisplay(props:MetricsDisplayProps) {
    const allMetricsData = useAppSelector(selectMetricsData);

    const renderData = Object.keys(allMetricsData).map((datastream:string, i:number) => {
                        return(
                            <MetricsCollapsible 
                                key={datastream} 
                                fetchPlots={props.fetchPlots} 
                                datastreamName={datastream} 
                                metricsData={allMetricsData[datastream]}
                                decomposeDatastream={props.decomposeDatastream}
                                getWikiLink={props.getWikiLink}
                                sendAlertEmail={props.sendAlertEmail}
                                submitReprocessingJob={props.submitReprocessingJob}
                            />
                        )
                    })

    return(
        <div>
            {allMetricsData.length !== 0 ?
                <div>
                    {renderData}
                </div>
            :
                <div>
                    <h1 style={{color:'red'}}>No Metrics Data</h1>
                </div>
            }
        </div>
    )
}


interface MetricsCollapsibleProps {
    datastreamName: string
    metricsData: any
    fetchPlots: any
    decomposeDatastream: any
    getWikiLink: any
    sendAlertEmail: any
    submitReprocessingJob: any
}

const MetricsCollapsible = (props: MetricsCollapsibleProps) => {

    const [isOpen, setIsOpen] = useState<boolean>(true)

    const getDatesBetweenDates = (startDate:any, endDate:any) => {
      let dates:any = []
      //to avoid modifying the original date
      const theDate = new Date(startDate)
      
      while (theDate < endDate) {
        dates = [...dates, new Date(theDate).toISOString().split('T')[0].replace(/-/g, '')]
        theDate.setDate(theDate.getDate() + 1)
      }
      dates = [...dates, endDate.toISOString().split('T')[0].replace(/-/g, '')]
      return dates
    }

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    // Get start/end dates from fetched data, then create a list of dates w/ no gaps for display.
    const queuedDatastreams = useAppSelector(selectQueuedDatastreams);
    let sdateString = ''
    let edateString = ''
    for(let i=0; i<queuedDatastreams.length; i++) {
        if (queuedDatastreams[i].name === props.datastreamName) {
            sdateString = queuedDatastreams[i].sdate
            edateString = queuedDatastreams[i].edate
            break
        }
    }

    // let sdate = parse(sdateString!, 'yyyyMMdd', new Date())
    // let edate = parse(edateString!, 'yyyyMMdd', new Date())

    // let displayDates = getDatesBetweenDates(sdate, edate)
    const triggerElement = <MetricsCollapsibleTrigger datastream={props.datastreamName} sdate={sdateString} edate={edateString}/>

    return(
        <div className={styles.MetricsContainer}>

            <Collapsible 
                // open={isOpen}
                onOpen={handleOpen}
                onClose={handleClose}
                // easing="ease-in"
                transitionTime={1}
                lazyRender={true}
                className={'MetricsCollapsible'} 
                openedClassName={'MetricsCollapsible'} 
                triggerOpenedClassName={'MetricsCollapsibleTrigger'} 
                triggerClassName={'MetricsCollapsibleTrigger'}
                contentInnerClassName={'MetricsCollapsibleContentInner'} 
                contentOuterClassName={'MetricsCollapsibleContentOuter'}
                // ⌄ This ignores erroneous typescript warnings for triggerSibling prop
                /*
                // @ts-ignore */
                triggerSibling={()=>{return(<MetricsCollapsibleLinks sdate={sdateString} edate={edateString} decomposeDatastream={props.decomposeDatastream}  datastreamName={props.datastreamName} getWikiLink={props.getWikiLink}/>)}}
                trigger={triggerElement}>

                {
                    Object.keys(props.metricsData).map((date:string, i:number) => {
                        if(("data_file" in props.metricsData[date]) && ("dq_errors" in props.metricsData[date])) {
                            return(
                                <div>
                                    <MissingMetricsDisplay submitReprocessingJob={props.submitReprocessingJob} sendAlertEmail={props.sendAlertEmail} datastreamName={props.datastreamName} date={date} dateTimeString={date.substring(0,4) + '-' + date.substring(4,6) + '-' + date.substring(6,8) } dataFile={props.metricsData[date]['data_file']} dqErrors={props.metricsData[date]['dq_errors']}/>
                                    <hr className={styles.RoundedDivider}/>
                                </div>
                            )
                        }
                        else if(Object.keys(props.metricsData[date]).length > 0 && !("dq_errors" in props.metricsData[date])) {
                            return(
                                Object.keys(props.metricsData[date]).map((time:string, i:number) => {
                                    return(
                                        <MetricsTableContainer decomposeDatastream={props.decomposeDatastream}  collapsibleIsOpen={isOpen} fetchPlots={props.fetchPlots} date={date} time={time} datastreamName={props.datastreamName} metricsData={props.metricsData} key={props.datastreamName + date + time}/>
                                    )
                                })
                            )
                        } else {
                            return(
                                <MetricsTableContainer decomposeDatastream={props.decomposeDatastream}  collapsibleIsOpen={isOpen} fetchPlots={props.fetchPlots} date={date} time={"000000"} datastreamName={props.datastreamName} metricsData={props.metricsData} key={props.datastreamName + date}/>
                            )
                        }
                    })
                }
            </Collapsible>
        </div>
    )
}

interface FileListTooltipProps {
    fileList: string[]
}
const FileListTooltip = (props:FileListTooltipProps) => {

    const cellContent = <div>
        <p>
        {props.fileList.map((filename:any, i:number) => {
            if(i === props.fileList.length - 1) {
                return(`${filename} `)
            } else {
                return(`${filename}, `)
            }

        })}
        </p>
    </div>

    return(
         <div className={styles.FileListExpandIconContainer}>
            <Tippy
              // options
               content={cellContent}
               placement="bottom"
            >   
            <span tabIndex={0}>
               <FontAwesomeIcon className={styles.FileListExpandIcon} icon={faPlusCircle} />
            </span>
                
            </Tippy>
        </div>
    )
}

interface MetricsCollapsibleLinksProps {
    datastreamName: string
    sdate: string
    edate:string
    decomposeDatastream: any
    getWikiLink: any
}
const MetricsCollapsibleLinks = (props:MetricsCollapsibleLinksProps) => {
    const dsMap = useAppSelector(selectDsMap)
    const [wikiLink, setWikiLink] = useState<string>("");

    let dsParts = props.decomposeDatastream(props.datastreamName)
    let inst_name = dsParts['instrument']
    let inst_class = ""

    if (dsMap !== null && dsMap !== undefined) {
        if (inst_name in dsMap) {
          inst_class = dsMap[inst_name]
      }
    }
    if (inst_class == "") {
        inst_class = inst_name
    }

    let dqaLink = "https://adc.arm.gov/dqa/#/create?fac=all&site=" + dsParts['site'] + "&inst=" + inst_class + "&sd=" + props.sdate + "&ed=" + props.edate

    useEffect(() => {
        props.getWikiLink(inst_name, (link:string) => {
            setWikiLink(link)
        })
    }, [])

    return(

        <div className={styles.MetricsCollapsibleTriggerLinksContainer}>
            <p><a href={dqaLink} target="_blank">DQA Report</a></p>
            <p><a href={wikiLink} target="_blank">DQ Wiki</a></p>
{/*            <p>CM Logs</p>*/}
        </div>

    )
}

interface MetricsCollapsibleTriggerProps {
    datastream: string
    sdate: string
    edate: string
}

const MetricsCollapsibleTrigger = (props: MetricsCollapsibleTriggerProps) => {
    return(
        <div className={styles.MetricsCollapsibleTriggerContainer}>
            <div className={styles.MetricsCollapsibleTriggerLabelContainer}>
                <p className={styles.MetricsCollapsibleTriggerLabel}>{props.datastream}</p>
                <p className={styles.MetricsCollapsibleTriggerDateLabel}>({props.sdate} - {props.edate})</p>
            </div>
        </div>
    )
}


interface MetricsTableContainerProps {
    date: string,
    time: string,
    datastreamName: string,
    metricsData: any,
    fetchPlots: any
    collapsibleIsOpen: boolean,
    decomposeDatastream: any
}

const MetricsTableContainer = (props:MetricsTableContainerProps) => {

    const [plotDisplayIsOpen, setPlotDisplayIsOpen] = useState<boolean>(false);

    const handleClick = () => {
        setPlotDisplayIsOpen(true)
    }

    const handlePlotDisplayClose = () => {
        setPlotDisplayIsOpen(false)
    }
  
    let dateTimeString = props.date.substring(0,4) + '-' + props.date.substring(4,6) + '-' + props.date.substring(6,8) 
    let queryTime = false
    if (Object.keys(props.metricsData[props.date]).length > 1) {
        dateTimeString += ':' + props.time
        queryTime = true
    }


    return(
        <div >
            {plotDisplayIsOpen ? <PlotDisplay datastreamName={props.datastreamName} date={props.date} time={queryTime ? props.time : null} fetchPlots={props.fetchPlots} onUnload={handlePlotDisplayClose} decomposeDatastream={props.decomposeDatastream} /> : null}
            <div>
                <div className={styles.MetricsDateContainer}>
                    <p>{dateTimeString}</p>

                </div>

               <div className={styles.MetricsVersionContainer}>
                    <p onClick={handleClick} className={styles.PlotsLink}><a>Plots</a><FontAwesomeIcon className={styles.PlotsIcon} icon={faImages} /></p>
                    <p><b>Ingest Version: </b>{props.metricsData[props.date][props.time]['process_version']}</p>
                    <p><b>File List: </b><FileListTooltip fileList={props.metricsData[props.date][props.time]['source']} /></p>
                    
                </div>
                <MetricsTable collapsibleIsOpen={props.collapsibleIsOpen} dateTimeString={dateTimeString} datastreamName={props.datastreamName} tableData = {props.metricsData[props.date][props.time]}/>
                <hr className={styles.RoundedDivider}/>
            </div>
        </div>
    )
}

interface MissingMetricsDisplayProps {
    sendAlertEmail: any
    datastreamName: string
    dateTimeString: string
    date: string
    dataFile: string[] | null
    dqErrors: number | null
    submitReprocessingJob: any
}
const MissingMetricsDisplay = (props:MissingMetricsDisplayProps) => {

    const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
    const [isShowingReprocessModal, setIsShowingReprocessModal] = useState<boolean>(false);

    const showModal = () => {
        setIsShowingModal(true)
    }
    const closeModal = () => {
        setIsShowingModal(false)
    }
    const showReprocessModal = () => {
        setIsShowingReprocessModal(true)
    }
    const closeReprocessModal = () => {
        setIsShowingReprocessModal(false)
    }

    let tooltipContent = ""
    let alertEmailDefaultMessage = ""
    let showAlertEmailButton = false
    let showReprocessButton = false

    if (props.dqErrors !== null && props.dqErrors !== 0) {
        tooltipContent += "DQO Processing Errors Detected"
        showAlertEmailButton = true
        alertEmailDefaultMessage = `Missing metrics file, DQO processing errors detected. \n\nDatastream: ${props.datastreamName} \nDate: ${props.dateTimeString}`
    }
    else if (props.dataFile !== null && props.dataFile.length !== 0) {
        tooltipContent += "No DQO processes detected. Data files found:\n"

        for (let file in props.dataFile) {
            tooltipContent += props.dataFile[file] +', \n'
        }
        alertEmailDefaultMessage = `Missing metrics file, no DQO processing jobs detected. \n\nDatastream: ${props.datastreamName} \nDate: ${props.dateTimeString}`
        // showAlertEmailButton = true
        showReprocessButton = true
    }
    if ((props.dqErrors === null || props.dqErrors === 0) && props.dataFile === null) {
        tooltipContent = "No data file found."
        showAlertEmailButton = false
    }

    return(
        <div>
            <div className={`${styles.MissingMetricsDateContainer} ${styles.MetricsDateContainer}`}>
                <p>{props.dateTimeString}</p>
                <Tippy
                   content={tooltipContent}
                   placement="right"
                >   
                    <span tabIndex={0}>
                        <FontAwesomeIcon className={styles.MissingMetricsAlertIcon} icon={faExclamationCircle} />
                    </span>
                </Tippy>
            </div>
            <div className={styles.MissingMetricsTextContainer}>
                <p>No Metrics File Found</p>
            </div>
            {showAlertEmailButton ?
                <div className={styles.MissingMetricsAlertButtonContainer}>
                    <button onClick={showModal} className={styles.MissingMetricsAlertButton}>Send Alert to DQO</button>
                </div>
            :
                null
            }
            {showReprocessButton ?
                <div className={styles.ReprocessButtonContainer}>
                    <button onClick={showReprocessModal} className={styles.ReprocessButton}>Submit Processing Job</button>
                </div>
            :
                null
            }

            <ReprocessModal closeModal={closeReprocessModal} showModal={showReprocessModal} isShowingModal={isShowingReprocessModal} submitReprocessingJob={props.submitReprocessingJob} datastreamName={props.datastreamName} date={props.date} dataFile={props.dataFile}/>
            <MissingMetricsAlertModal closeModal={closeModal} showModal={showModal} isShowingModal={isShowingModal} defaultComment={alertEmailDefaultMessage} sendAlertEmail={props.sendAlertEmail}/>
        </div>
    )
}

interface ReprocessModalModalProps {
    closeModal:any
    showModal:any
    isShowingModal: boolean
    datastreamName:string
    date:string
    dataFile: string[] | null
    submitReprocessingJob: any
}

export const ReprocessModal = (props:ReprocessModalModalProps) => {

  const [email, setEmail] = useState<string>('');
  const [submitIsDisabled, setSubmitIsDisabled] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Submit');
  const [sendEmail, setSendEmail] = useState<boolean>(false);

  const handleSendEmailToggle = () => {
    setSendEmail(!sendEmail)
  }

  const handleCloseModal = () => {
    props.closeModal()
  }

  const handleEmailChange = (e:any) => {
    setEmail(e.target.value)
  }

  const handleSubmitJob = () => {
    setSubmitIsDisabled(true)

    let temp_email:string|null = email
    if (email === '') {
        temp_email = null
    }
    props.submitReprocessingJob(props.datastreamName, props.date, props.date, temp_email, (res:string) => {
      if (res === "success") {
        setButtonText('Job Submission Request Sent')
        setTimeout(() => {
          handleCloseModal()
          setSubmitIsDisabled(false)
          setButtonText('Submit')
        }, 2000)
      } else {
        setButtonText('Error Submitting Job')
        setTimeout(() => {
          handleCloseModal()
          setSubmitIsDisabled(false)
          setButtonText('Submit')
        }, 2000)
      }
    })
  }

  return(
    <div>
      <Modal open={props.isShowingModal} onClose={handleCloseModal} classNames={{ overlay: styles.ModalOverlay, modal: styles.Modal, closeButton:styles.ModalCloseButton, closeIcon:styles.ModalCloseIcon}} center>
        <div style={{minWidth:250}}>
          <div >
            <h3 style={{margin:0, paddingBottom:10}}>Submit Processing Job to Queue</h3>
          </div>
          <div style={{marginBottom: 20}}>
              <table className={styles.ReprocessDataTable}>
                  <tr>
                    <td><b>Datastream:</b></td> 
                    <td>{props.datastreamName}</td>
                  </tr>
                  <tr>
                    <td><b>Date:</b></td> 
                    <td>{props.date}</td>
                  </tr>
                  <br/>
                  <tr>
                    <td><b>Send email on completion?</b></td> 
                    <td style={{textAlign:'right'}}> <Switch onChange={handleSendEmailToggle} checked={sendEmail} /> </td>
                  </tr>
              </table> 
              {sendEmail ? 
                <input onChange={handleEmailChange}  placeholder="Enter your email..." value={email} className={styles.ModalInput}/>
              :
                null
              }
          </div>
          <button disabled={submitIsDisabled} onClick={handleSubmitJob} className={styles.ModalButton}>{buttonText}</button>
        </div>
      </Modal>
    </div>
  )
}


interface MissingMetricsAlertModalProps {
    closeModal:any
    showModal:any
    isShowingModal: boolean
    defaultComment: string
    sendAlertEmail: any
}

export const MissingMetricsAlertModal = (props:MissingMetricsAlertModalProps) => {


  const [email, setEmail] = useState<string>('');
  const [comment, setComment] = useState<string>(props.defaultComment);
  const [sendIsDisabled, setSendIsDisabled] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Send');

  const handleCloseModal = () => {
    setEmail('')
    setComment(props.defaultComment)
    props.closeModal()
  }

  const handleEmailChange = (e:any) => {
    setEmail(e.target.value)
  }
  const handleCommentChange = (e:any) => {
    setComment(e.target.value)
  }

  const handleSendEmail = () => {
    setSendIsDisabled(true)

    props.sendAlertEmail(email, comment, (res:string) => {
      if (res === "success") {
        setButtonText('Alert Sent')
        setTimeout(() => {
          handleCloseModal()
          setSendIsDisabled(false)
          setButtonText('Send')
        }, 2000)
      } else {
        setButtonText('Error Sending Alert')
        setTimeout(() => {
          handleCloseModal()
          setSendIsDisabled(false)
          setButtonText('Send')
        }, 2000)
      }
    })
  }

  return(
    <div>
      <Modal open={props.isShowingModal} onClose={handleCloseModal} classNames={{ overlay: styles.ModalOverlay, modal: styles.Modal, closeButton:styles.ModalCloseButton, closeIcon:styles.ModalCloseIcon}} center>
        <div style={{minWidth:250}}>
          <div >
            <h3 style={{margin:0, paddingBottom:20}}>Send Alert</h3>
          </div>
          <div>
            <p style={{margin:5}}><b>Email Address:</b></p>
            <input onChange={handleEmailChange}  placeholder="Enter your email..." value={email} className={styles.ModalInput}/>

            <br/>
            <br/>

            <p style={{margin:5}}><b>Description/Comment:</b></p>
            <textarea onChange={handleCommentChange} placeholder="Describe the issue or leave a comment..." style={{height:70}} value={comment} className={styles.ModalInput}/>

            <br/>
            <br/>

            <button disabled={sendIsDisabled} onClick={handleSendEmail} className={styles.ModalButton}>{buttonText}</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}


interface MetricsTableProps {
    tableData: any
    datastreamName: string
    dateTimeString: string
    collapsibleIsOpen: boolean
}

const MetricsTable = (props: MetricsTableProps) => {

    let numCols = 24
    if (props.tableData !== null && 'range' in props.tableData) {
        numCols = props.tableData['range'].steps.length
    }

    let columnHeaders =  Array.from(Array(numCols).keys()).map((e, i) => {
        if (props.tableData !== null && 'range' in props.tableData) {
            return(props.tableData['range']['steps'][i])
        } else {
            return(e.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}))
        }
    })

    let columnUnits = (props.tableData !== null && 'range' in props.tableData) ? (props.tableData['range']['name'] + " (" + props.tableData['range']['units'] + ")") : "Hours (hr)"

    return(
        <div className={styles.MetricsTableContainer}>
            <table className={styles.MetricsTable}>
                <colgroup>
                    <col style={{width:'30%'}} />
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        <th className={styles.MetricsTableHeaderLabel} colSpan={numCols}>{columnUnits}</th>
                    </tr>
                    <tr>
                        <th></th>
                        {
                            Array.from(columnHeaders).map((e, i) => {
                                return(
                                    <th key={i} className={styles.MetricsTableHeaderLabelCell}> 
                                        {e} 
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.tableData['variables'].map((e:any, i:number) => {
                            return(
                               <MetricsTableRow key={props.datastreamName+props.dateTimeString+"row"+i.toString()} collapsibleIsOpen={props.collapsibleIsOpen} dateTimeString={props.dateTimeString} datastreamName={props.datastreamName} columnUnits={columnUnits} columnHeaders={columnHeaders} rowData={e}/>
                            )
                        })
                    }
            </tbody>
            </table>
        </div>
    )
}

interface MetricsTableRowProps {
    rowData: any
    columnHeaders: string[]
    columnUnits: string
    datastreamName: string
    dateTimeString: string
    collapsibleIsOpen: boolean
}

const MetricsTableRow = (props: MetricsTableRowProps) => {

    const [fieldMetricsIsOpen, setFieldMetricsIsOpen] = useState<boolean>(false);

    let cellTests: Array<any> = []
    let cellAssessments: Array<any> = []
    let goodAssessments = []

    let numCols = props.rowData['qc_tests'][0]['percent'].length


    for (let i=0; i<numCols; i++){
        let testArray = props.rowData['qc_tests']
        let currTests = []
        

        for (let j=0; j<testArray.length; j++) {
            let testObj = testArray[j]
            let cellTestObj = {}

            if (testObj.percent[i] > 0) {
                cellTestObj = {
                    assessment: testObj.assessment,
                    long_name: testObj.long_name,
                    percent: testObj.percent[i],
                    sample: testObj.sample[i],
                    test_name: testObj.test_name
                }
                currTests.push(cellTestObj)
            }

            if (testObj.test_name === "__good") {
                goodAssessments.push(testObj.percent[i])
            }

        }
        cellTests.push(currTests)
    }

    for (let i=0; i<cellTests.length; i++) {
        let currCellTests: Array<any> = cellTests[i]
        let cellAssessment = "Good"
        let multipleAssessmentsFlag = false

        if (goodAssessments[i] != 100) {
            for (let j=0; j<currCellTests.length; j++) {
                let testObj = currCellTests[j]

                if (multipleAssessmentsFlag) {
                    if (cellAssessment != testObj.assessment) {
                        cellAssessment = 'Multiple'
                    }
                }
                else {
                    if (testObj.assessment != 'Good') {
                        multipleAssessmentsFlag = true
                        cellAssessment = testObj.assessment
                    }
                }
            }
        }

        cellAssessments.push(cellAssessment)
    
    }

    const handleClick = () => {
        setFieldMetricsIsOpen(true)
    }

    const handleFieldMetricsClose = () => {
        setFieldMetricsIsOpen(false)
    }
    

    return (
        <tr> 
            {fieldMetricsIsOpen ? <FieldMetricsDisplay datastreamName={props.datastreamName} dateTimeString={props.dateTimeString} columnUnits={props.columnUnits} columnHeaders={props.columnHeaders} fieldData={props.rowData} onUnload={handleFieldMetricsClose}/> : null}
            
            <td onClick={handleClick} className={styles.MetricsTableRowLabel}>
                <LazyTippy
                    content={props.rowData['attributes']['long_name']}
                    placement="right"
                >
                    <div className={styles.MetricsTableRowLabelInnerDiv}>
                        {props.rowData['field_name']}
                    </div>
                </LazyTippy>
            </td> 
            
            {Array.from(Array(numCols).keys()).map((e, i) => {
                return(
                    <MetricsTableCell collapsibleIsOpen={props.collapsibleIsOpen} columnHeader={props.columnHeaders[i]} key={props.rowData['field_name'] + i.toString()} cellTests={cellTests[i]} cellAssessment={cellAssessments[i]} cellIndex={i}/>
                )
            })}
        </tr>
    )
}


const metricsAssessmentColorMap = new Map<string, string>(); 
metricsAssessmentColorMap.set('Multiple', '#59f')
metricsAssessmentColorMap.set('Bad', '#f77')
metricsAssessmentColorMap.set('Indeterminate', '#ff7')
metricsAssessmentColorMap.set('Missing', '#d4d4d4')
metricsAssessmentColorMap.set('Not_Available', '#ffc488')
metricsAssessmentColorMap.set('Power_Outage', '#8C4C73')
metricsAssessmentColorMap.set('Tip_Curve', '#c6ffaa')
metricsAssessmentColorMap.set('Good', '#afa')


interface MetricsTableCellProps {
    cellAssessment: any
    cellTests: any
    cellIndex: number
    columnHeader: string
    collapsibleIsOpen: boolean
}
const MetricsTableCell = (props: MetricsTableCellProps) => {

    const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
    const [tooltipIsClicked, setTooltipIsClicked] = useState<boolean>(false);

    const handleMouseEnter = () => {
        setTooltipIsOpen(true)
    }

    const handleMouseLeave = () => {
        setTooltipIsOpen(false)
    }

    const handleClick = (e:any) => {
        setTooltipIsClicked(!tooltipIsClicked)
    }

    const handleClickOutside = () => {
        setTooltipIsClicked(false)
    }

    useEffect(() => {
        if(props.collapsibleIsOpen === false) {
            setTooltipIsOpen(false)
            setTooltipIsClicked(false)
        }
    }, [props.collapsibleIsOpen])


    const cellColor = metricsAssessmentColorMap.get(props.cellAssessment)

    const cellContent = <div onClick={(e:any)=>{e.stopPropagation()}}>
        {props.cellTests.map((test:any, i:number) => {
            let tooltipSectionColor = metricsAssessmentColorMap.get(test.assessment)

            if (test.long_name.indexOf('URLS:') > -1 ) {
                let urlStart = test.long_name.indexOf('URLS:')
                let urlString = test.long_name.substring(urlStart, test.long_name.length)
                test.long_name = test.long_name.replace(urlString, '')
                let urlData = urlString.replace('URLS:', '').split(';')
                
                if( urlData !== undefined && urlData != null ) {
                    for(var i=0; i<urlData.length; ++i) {
                        let currUrlData = urlData[i]
                        if (currUrlData !== ''){
                            let urlName = ""
                            let urlLink = ""
                            let currUrlDataSplit = currUrlData.replace(' ', '').split(',')

                            if (currUrlDataSplit.length == 2) {
                                urlName = currUrlDataSplit[1]
                                urlLink = currUrlDataSplit[0]
                            } else {
                                urlName = currUrlDataSplit[0]
                                urlLink = currUrlDataSplit[0]
                            }

                            let linkHtmlElement = '<a target="_blank" href="' + urlLink + '">' + urlName + '</a>'
                            if (i < urlData.length - 1){
                                linkHtmlElement += ", "
                            }
                            test.long_name += linkHtmlElement
                        }
                    }
                }
            }

            return(
                <div key={i} className={styles.MetricsTableCellTooltipDiv} style={{'background':tooltipSectionColor}}>
                    <div>
                        <p className={styles.MetricsTableCellTooltipAssessmentText}>{test.assessment}</p>
                        <p className={styles.MetricsTableCellTooltipPercentText}>{test.percent}%</p>
                    </div>
                    {tooltipIsClicked ? 
                    <div className={styles.MetricsTableCellTooltipDescription}>
                        <p dangerouslySetInnerHTML={{ __html: test.long_name }}></p><br/>
                        <p><b>Time:</b> {test.sample}</p>
                    </div>
                    :
                    null
                    }
                </div>
            )
        })}
    </div>

    return (
        <td onClick={handleClick} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} className={`${props.cellAssessment} ${styles.MetricsTableCell}`} >
            <LazyTippy
                visible={(tooltipIsOpen || tooltipIsClicked) && props.collapsibleIsOpen}
                content={cellContent}
                placement="bottom"
                className={styles.MetricsTableCellTooltip}
                interactive={true}
            >
               <div className={styles.MetricsTableCellDiv} style={{background: cellColor}}>
                   <p>{props.columnHeader}</p>
               </div>
            </LazyTippy>
        </td>
    )
}

