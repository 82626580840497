import React, { useState, useEffect, useRef, Component } from 'react';
import NewWindow from 'react-new-window'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';

import styles from './PlotDisplay.module.css';

interface PlotDisplayProps {
	datastreamName: string,
	date: string,
	time: string | null,
	fetchPlots: any,
    onUnload: any,
    decomposeDatastream: any
}

export function PlotDisplay(props:PlotDisplayProps) {

	const [plotsLoaded, setPlotsLoaded] = useState<boolean>(false);
	const [loadingError, setloadingError] = useState<boolean>(false);
	const [plotsData, setPlotsData] = useState<any>({});
	const [plotsDate, setPlotsDate] = useState<string>(props.date);
	const [plotsTime, setPlotsTime] = useState<string|null>(props.time);
	const [selectedPlot, setSelectedPlot] = useState<string|null>(null);

	const plotRefs = useRef({})

	// useEffect(() => {
	//     props.fetchPlots(props.datastreamName, plotsDate, (result:any) => {
	//     	if ("error" in result) {
	//     		setloadingError(true)
	//     	} else {
	//     		setPlotsData(result)
	//     		setPlotsLoaded(true)
	//     	}
	//     })
	// }, [])

	useEffect(() => {
		
	},[])

	useEffect(() => {
		updateData()
	}, [plotsDate])

	const goToNextDay = () => {
		let nextDate = moment(plotsDate, "YYYYMMDD").startOf('day').add(1,'days').format('YYYYMMDD')
		setPlotsDate(nextDate)
	}

	const goToPrevDay =() => {
		let prevDate = moment(plotsDate, "YYYYMMDD").startOf('day').subtract(1,'days').format('YYYYMMDD')
		setPlotsDate(prevDate)
	}

	const handleNavClick = (selectedPlotName:string) => {
		setSelectedPlot(selectedPlotName)
	}

	const updateData = () => {
		setPlotsLoaded(false)
		setPlotsData({})

		props.fetchPlots(props.datastreamName, plotsDate, plotsTime, (result:any) => {
	    	if ("error" in result) {
	    		setloadingError(true)
	    	} else {
	    		setPlotsData(result)
	    		setPlotsLoaded(true)
	    		setTimeout(function(){
					// @ts-ignore */
					if(selectedPlot !== null && plotRefs.current[selectedPlot] !== null){
						// @ts-ignore */
						plotRefs.current[selectedPlot].scrollIntoView({behavior: "smooth"})
					}
				}, 500);
	    	}
	    })
	}

	const genPlotBrowserLink = (plotNames:string[], isComp:boolean) => {
		let dsParts = props.decomposeDatastream(props.datastreamName)
		let pbURL = 'https://dq.arm.gov/dq-plotbrowser/'
		if(isComp){
			pbURL += '?comp=true&'
		} else {
			pbURL += '?comp=false&'
		}
		pbURL += `s=${dsParts['site']}&c=${dsParts['instrument']}&f=${dsParts['facility']}&l=${dsParts['data_level']}`
		for (let i=0; i<plotNames.length;i++) {
			pbURL += `&p=${plotNames[i]}`
		}
		pbURL += `&sd=${plotsDate}&ed=${moment(plotsDate, "YYYYMMDD").startOf('day').add(6,'days').format('YYYYMMDD')}`
		return pbURL
	}

	

	return(
		<NewWindow name={window.name} title={props.datastreamName + " plots"} center="screen" features={{'width':800, 'height': 1000, 'top':0, 'left':0}} onUnload={props.onUnload}>
			{ plotsLoaded ?
				<div>
					<PlotNavBar 
						goToNextDay={goToNextDay}
						goToPrevDay={goToPrevDay}
						diagnosticPlotNames={plotsData['diagnostic']['plot_names']}
						diagnosticMovieNames={plotsData['diagnostic']['movie_names']}  
						comparisonPlotNames={plotsData['comparison']['plot_names']}
						comparisonMovieNames={plotsData['comparison']['movie_names']}
						handleNavClick={handleNavClick}  
					/>
					<div className={styles.PlotContainer}>
						<p className={styles.DateLabel}>{plotsDate}</p>
						<a target='_blank' href={genPlotBrowserLink(plotsData['diagnostic']['plot_names'], false)}><p className={styles.PlotContainerHeader}>Diagnostic Plots</p></a>
						{plotsData['diagnostic']['plot_names'].map((plotName:string, i:number) => {
							return(
								// @ts-ignore */
								<div ref={(element) => plotRefs.current[plotName] = element}><Plot plotName={plotName} url={plotsData['diagnostic']['plots'][plotName]} isMovie={false} isCompPlot={false} genPlotBrowserLink={genPlotBrowserLink}/></div>
							)
						})}
						{plotsData['diagnostic']['movie_names'].map((plotName:string, i:number) => {
							return(
								// @ts-ignore */
								<div ref={(element) => plotRefs.current[plotName] = element}><Plot plotName={plotName} url={plotsData['diagnostic']['movies'][plotName]} isMovie={true} isCompPlot={false} genPlotBrowserLink={genPlotBrowserLink}/></div>
							)
						})}

						<a target='_blank' href={genPlotBrowserLink(plotsData['comparison']['plot_names'], true)}><p className={styles.PlotContainerHeader}>Comparison Plots</p></a>
						{plotsData['comparison']['plot_names'].map((plotName:string, i:number) => {
							return(
								// @ts-ignore */
								<div ref={(element) => plotRefs.current[plotName] = element}><Plot plotName={plotName} url={plotsData['comparison']['plots'][plotName]} isMovie={false} isCompPlot={true} genPlotBrowserLink={genPlotBrowserLink}/></div>
							)
						})}
						{plotsData['comparison']['movie_names'].map((plotName:string, i:number) => {
							return(
								// @ts-ignore */
								<div ref={(element) => plotRefs.current[plotName] = element}><Plot plotName={plotName} url={plotsData['comparison']['movies'][plotName]} isMovie={true} isCompPlot={true} genPlotBrowserLink={genPlotBrowserLink}/></div>
							)
						})}
					</div>
				</div>
			:
				loadingError ?
					<ErrorDisplay/>
				:
					<LoadingDisplay/>				
			}
		</NewWindow>
	)
}



interface PlotProps {
	plotName: string,
	url: string,
	isMovie: boolean,
	isCompPlot:boolean
	genPlotBrowserLink:any
}
const Plot = (props:PlotProps) => {

	return(
		<div id={props.plotName} >
			<a target='_blank' href={props.genPlotBrowserLink([props.plotName], props.isCompPlot)}><p className={styles.PlotLabel}>{props.plotName}</p></a>
			{props.url === null ? 
					<FontAwesomeIcon className={styles.MissingPlotIcon} icon={faTimes} />
				:
				<div>
					{props.isMovie ? 
						<video controls src={props.url}/>
					:
						<img src={props.url}/>
					}
				</div>
			}
			
		</div>
	)
}

interface PlotNavBarProps {
	goToNextDay: any
	goToPrevDay: any
	diagnosticPlotNames: string[]
	diagnosticMovieNames: string[]
	comparisonPlotNames: string[]
	comparisonMovieNames: string[]
	handleNavClick: any
}
const PlotNavBar = (props: PlotNavBarProps) => {
	return(
		<div className={styles.NavBarContainer}>
			<div className={styles.DropdownContainer}>
				<button className={styles.dropbtn}>
				  {"Diagnostic Plots "}
				  <i className="fa fa-caret-down"></i>
				</button>
				<div className={styles.DropdownContent}>
					{props.diagnosticPlotNames.length > 0 ? 
						<div>
							<p>Plots</p>
							{props.diagnosticPlotNames.map((plotName:string, i:number) => {
								return(<a onClick={() => {props.handleNavClick(plotName)}} href={"about:blank#" + plotName}>{plotName}</a>)
							})}
						</div>
					:
						null
					}

					{props.diagnosticMovieNames.length > 0 ? 
						<div>
							<p>Movies</p>
							{props.diagnosticMovieNames.map((plotName:string, i:number) => {
								return(<a onClick={() => {props.handleNavClick(plotName)}} href={"about:blank#" + plotName}>{plotName}</a>)
							})}
						</div>
					:
						null
					}
				</div>
			</div>
			<div className={styles.DropdownContainer}>
				<button className={styles.dropbtn}>
				  {"Comparison Plots "}
				  <i className="fa fa-caret-down"></i>
				</button>
				<div className={styles.DropdownContent}>
					{props.comparisonPlotNames.length > 0 ? 
						<div>
							<p>Plots</p>
							{props.comparisonPlotNames.map((plotName:string, i:number) => {
								return(<a onClick={() => {props.handleNavClick(plotName)}} href={"about:blank#" + plotName}>{plotName}</a>)
							})}
						</div>
					:
						null
					}

					{props.comparisonMovieNames.length > 0 ? 
						<div>
							<p>Movies</p>
							{props.comparisonMovieNames.map((plotName:string, i:number) => {
								return(<a onClick={() => {props.handleNavClick(plotName)}} href={"about:blank#" + plotName}>{plotName}</a>)
							})}
						</div>
					:
						null
					}
				</div>
			</div>
			<div className={styles.DateAdvanceDiv}>
				<button className={styles.DateAdvanceButton} onClick={props.goToPrevDay}>Prev Day</button>
				<button className={styles.DateAdvanceButton} onClick={props.goToNextDay}>Next Day</button>
			</div>

		</div>
	)
}

const LoadingDisplay = () => {

	return (
		<div className={styles.LoadingDisplay}>
	        <p>Loading Plots...</p>
	        <br/><FontAwesomeIcon className={`${styles.LoadingDisplaySpinner} fa-spin`} icon={faSpinner} size="3x"/>
		</div>
	)
}

const ErrorDisplay = () => {

	return (
		<div className={styles.ErrorDisplay}>
			<p></p>
		</div>
	)
}