import React, {useState, useEffect, useRef} from 'react';
import {
  Switch,
  Route,
  useLocation,
  useHistory
} from "react-router-dom";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import queryString from 'query-string'
import moment from 'moment';



import { datastreamRequestData, dqExplorerFavorite } from './types'
import { useAppDispatch, useAppSelector } from './app/hooks';

import { MenuBar } from './features/menuBar/MenuBar';
import { LoginModal } from './features/menuBar/LoginModal';
import { RequestQueue } from './features/requestQueue/RequestQueue';
import { MetricsDisplay } from './features/metricsDisplay/MetricsDisplay';
import { UserDashboard } from './features/userDashboard/UserDashboard';
import { LoadingOverlay } from './features/loadingOverlay/LoadingOverlay';
import { FloatingShareButton } from './features/floatingShareButton/FloatingShareButton';
import { FloatingFavoritesButton } from './features/floatingFavoritesButton/FloatingFavoritesButton';
import { HelpFooter } from './features/helpFooter/HelpFooter';

import { setQueuedDatastreams, 
    setMetricsData, 
    setQueryUserDB, 
    setSelectedQueryUser, 
    setDsMap,
    selectQueuedDatastreams, 
    selectQueryUserDB, 
    selectSelectedQueryUser, 
    selectQueryUserOptions,
    selectDsMap
} from './features/requestQueue/requestQueueSlice';

import {
  setIsAuthenticating,
  setIsAuthenticated,
  setAuthenticationMessage,
  setCurrentUser,
  selectCurrentUser,
  setLoginModalIsOpen
} from './features/menuBar/authSlice';

import {
  setAnalystAssignments,
  setAnalystAssignmentCompletion,
  setDqaWeekStart,
  setDqaWeekEnd,
  selectDqaWeekStart,
  selectDqaWeekEnd,
  setUserFavorites
} from './features/userDashboard/userDashboardSlice';

import './App.css';

const CancelToken = axios.CancelToken;
let cancel:any;


function App() {

  const [apiBaseURL, setApiBaseURL] = useState<string>('');
  const [appLoaded, setAppLoaded] = useState<boolean>(false)
  const [isLoadingFromQS, setIsLoadingFromQS] = useState<boolean>(false)

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const queryUserDB = useAppSelector(selectQueryUserDB)
  const selectedQueryUser = useAppSelector(selectSelectedQueryUser)
  const queryUserOptions = useAppSelector(selectQueryUserOptions)
  const dqaWeekStart = useAppSelector(selectDqaWeekStart);
  const dqaWeekEnd = useAppSelector(selectDqaWeekEnd);


  const { search } = useLocation()
  const qsObj = queryString.parse(search)
  const history = useHistory()
  const isInitialMount = useRef(true);

  useEffect(() => {
    getDQAWeek()
    getBaseURL()
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
       isInitialMount.current = false;
    } else {
      
      getDsMap((res:any) => {
        let dsMap = res['ds_map']
        dispatch(setDsMap(dsMap))

        if(checkQueryString(qsObj)){
          loadFromQueryString(() => {
            setAppLoaded(true)
          })
        }
        else {
          setAppLoaded(true)
        }

        // SET USER IF ALREADY LOGGED IN PREVIOUS SESSSION
        if (!!localStorage.token) {

          let decoded = jwt_decode(localStorage.token) as any

          getFavorites(decoded.id, () => {
            getAnalystAssignments(decoded.id, dqaWeekStart, dqaWeekEnd, () => {
              dispatch(setIsAuthenticated(true))
              dispatch(setCurrentUser({
                'firstName': decoded.first_name,
                'lastName': decoded.last_name,
                'armID': decoded.id,
                'username': decoded.username
              }))
            })
          })

        }
      })
    }
    
  },[apiBaseURL])


  const loadFromQueryString = (callback:any) => {
    setIsLoadingFromQS(true)

    let username = 'dqmgr'
    if ('u' in qsObj){
      username = qsObj['u']! as string
    }

    if (username !== 'dqmgr') {
      dispatch(setQueryUserDB(true))
      dispatch(setSelectedQueryUser(username))
    }
    else {
      dispatch(setQueryUserDB(false))
      dispatch(setSelectedQueryUser(null))
    }

    let datastreams = qsObj['d']!
    let sdates      = qsObj['s']!
    let edates      = qsObj['e']!

    if(!Array.isArray(datastreams)) {
      datastreams = [datastreams]
    }
    if(!Array.isArray(sdates)) {
      sdates = [sdates]
    }
    if(!Array.isArray(edates)) {
      edates = [edates]
    }
    let reqData:datastreamRequestData[] = []

    for (let i=0; i<datastreams.length; i++) {

      let ds = datastreams[i]
      let sdate = sdates[i]
      let edate = edates[i]

      // Handle sdate calulations
      if(sdate.includes("$latest")) {

        if(sdate.includes("-")) {
          let sdate_parts = sdate.split('-')
          sdate = moment().subtract(parseInt(sdate_parts[1]),'days').format('YYYYMMDD')
        } else {
          sdate = moment().format('YYYYMMDD')
        }
      }

      // Handle edate calulations
      if(edate.includes("$latest")) {

        if(edate.includes("-")) {
          let edate_parts = edate.split('-')
          edate = moment().subtract(parseInt(edate_parts[1]),'days').format('YYYYMMDD')
        } else {
          edate = moment().format('YYYYMMDD')
        }
      }

      reqData.push({'name':ds, 'sdate':sdate, 'edate':edate})
    }
    

    dispatch(setQueuedDatastreams(reqData))

    fetchMetricsData(reqData, (metricsDataArr:any) => {
      dispatch(setMetricsData(metricsDataArr))

      setTimeout(() => {
        history.push('/metrics' + search)
        setIsLoadingFromQS(false)
        callback()
      }, 200)
     
    }, username as string)
  }

  const checkQueryString = (qsObj:any) => {

    let isValidUsername = false
    let hasKeys = false

    if ('u' in qsObj) {
      let username = qsObj['u']!
      if (username != 'dqmgr') {
        for (let i=0;i<queryUserOptions.length;i++) {
          if (queryUserOptions[i].value === username) {
            isValidUsername = true
            break
          }
        }
      }
      else {
        isValidUsername = true
      }
    }
    else {
      isValidUsername = true
    }

    if (Object.entries(qsObj).length !== 0) {
      hasKeys = true
    }
    

    return (isValidUsername && hasKeys)
  }

   const getDQAWeek = () => {

      var t = new Date().getUTCDate() + (6 - new Date().getUTCDay()) - 14 ;
      var dqaStart = new Date();
      dqaStart.setDate(t);

      var t = new Date().getUTCDate() + (6 - new Date().getUTCDay() - 1) - 7 ;
      var dqaEnd = new Date();
      dqaEnd.setDate(t);

      dispatch(setDqaWeekStart(moment(dqaStart).format('YYYYMMDD')))
      dispatch(setDqaWeekEnd(moment(dqaEnd).format('YYYYMMDD')))

  }


  const getBaseURL = () => {
    let url = window.location.href;

    //Is local dev instance
    if (url.indexOf("localhost") >= 0){
      setApiBaseURL('http://localhost:7100/')
    }
    //Is Production System
    else{
      setApiBaseURL('${APP_DQ_API_URL}/')
    }
  }

  const getDsMap = (callback:any) => {

    axios.post(apiBaseURL + 'get_inst_class_map', {},
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).catch(function(thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        // handle error
      }
    }).then((res:any) => {
      // let res_any = res as any
      let result = res.data
      callback(result)
    })
  }

  const decomposeDatastream = (datastream:string) => {
    let site = ''
    let instrument = ''
    let facility = ''
    let data_level = ''

    if(datastream!==''){
      let dot_index = datastream.indexOf(".");
      let instrument_and_facility = datastream.slice(3, dot_index);

      site = datastream.slice(0,3);
      data_level = datastream.slice(dot_index + 1);
      let facility_index = instrument_and_facility.search(/[A-Z]+[0-9]+/);
      instrument = instrument_and_facility.slice(0, facility_index);
      facility = instrument_and_facility.slice(facility_index);
    }
    return {"site": site, "instrument": instrument, "facility": facility, "data_level":data_level};
  }

  const checkTaskStatus = (task_id:string, callback:any) => {
    let query_data = {
      'task_id': task_id
    }

    axios.post(apiBaseURL + 'task_status', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      let data = res.data
      if (data['state'] == "FAILURE") {
        callback({'error': data['status']})
      }
      else if (data['state'] != 'PENDING' && data['status'] != 'Pending' && data['state'] != 'PROGRESS') {
          if ('result' in data) {
              callback(data['result'])
          }
          else {
              callback({})
          }
      }
      else {
          // rerun in 2 seconds
          setTimeout(function() {
              checkTaskStatus(task_id, callback);
          }, 500);
      }
    })
  }

  const submitReprocessingJob = (datastream:string, sdate:string, edate:string, email:string, callback:any) => {
    axios.post(apiBaseURL + 'submit_scheduler_reprocessing_job', {
      'datastream':datastream,
      'sdate': sdate,
      'edate': edate,
      'email': email
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).catch(function(thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        // handle error
      }
    }).then((res:any) => {
      // let res_any = res as any
      let result = res.data
      callback(result)
    })
  }


  const fetchMetricsData = (requestData:datastreamRequestData[], callback:any, username:string|null=null) => {
    if (username === null) {
      if (queryUserDB && selectedQueryUser !== null) {
        username = selectedQueryUser
      }
      else {
        username = "dqmgr"
      }
    } 
    
    axios.post(apiBaseURL + 'get_dqexplorer_metrics_data',{
      'datastream_data': requestData,
      'username': username
     },
     {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      }).catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      }).then((res:any) => {
        // let res_any = res as any
        let result = res.data
        let task_id = result.task_id

        checkTaskStatus(task_id, (result:any) => {
          callback(result)
        })
      })

  }


  const fetchDatastreams = (queryUserDB:boolean, callback:any, username:string|null=null) => {

    if (username === null) {
      if (queryUserDB && selectedQueryUser !== null) {
        username = selectedQueryUser
      }
      else {
        username = "dqmgr"
      }
    }

    axios.post(apiBaseURL + 'get_dqexplorer_datastreams',{
      'username': username
     }, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      }).catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      }).then((res:any) => {
        // let res_any = res as any
        let result = res.data
        let task_id = result.task_id

        checkTaskStatus(task_id, (result:any) => {
          callback(result)
        })
      })

  }

  const fetchDates = (datastream:string, callback:any, username:string|null=null) => {
    cancel()
    
    if (username === null) {
      if (queryUserDB && selectedQueryUser !== null) {
        username = selectedQueryUser
      }
      else {
        username = "dqmgr"
      }
    }

    axios.post(apiBaseURL + 'get_dqexplorer_dates',{
      'ds':datastream,
      'username':username
     },
     {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      }).catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      }).then((res:any) => {
        // let res_any = res as any
        let result = res.data
        let task_id = result.task_id

        checkTaskStatus(task_id, (result:any) => {
          callback(result)
        })
      })
  }

  const fetchPlots = (datastream:string, date:string, time:string, callback:any, username:string|null=null) => {
    if (username === null) {
      if (queryUserDB && selectedQueryUser !== null) {
        username = selectedQueryUser
      }
      else {
        username = "dqmgr"
      }
    }

    axios.post(apiBaseURL + 'get_dqexplorer_plots',{
      'username': username,
      'datastream': datastream,
      'date':date,
      'time':time
     }, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      }).catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      }).then((res:any) => {
        // let res_any = res as any
        let result = res.data
        let task_id = result.task_id

        checkTaskStatus(task_id, (result:any) => {
          callback(result)
        })
      })
  }

  const logOutUser = () => {
    localStorage.clear()
    dispatch(setCurrentUser(null))
    dispatch(setIsAuthenticated(false))
    dispatch(setAuthenticationMessage(""))
  }

  const getAnalystAssignments = (armid:string, dqaStart:string, dqaEnd:string, callback:any) => {
    let query_data = {'armid': armid, 'dqa_start':dqaStart, 'dqa_end':dqaEnd}
    axios.post(apiBaseURL + 'get_dqexplorer_analyst_assignments', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      dispatch(setAnalystAssignmentCompletion(res.data['assignment_completion']))
      dispatch(setAnalystAssignments(res.data['assignments']))
      callback()
    }).catch((reason: any) => {
      callback()
    })
  }

  const getFavorites = (armid:string, callback:any) => {
    let query_data = {'armid': armid}
    axios.post(apiBaseURL + 'get_dqexplorer_favorites', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      console.log(res.data['favorites'])
      dispatch(setUserFavorites(res.data['favorites']))
      callback()
    }).catch((reason: any) => {
      callback()
    })
  }

  const saveFavorite = (armid:string, favData:dqExplorerFavorite, callback: any) => {
    let query_data = {'armid': armid, 'datastreams': favData.datastreams, 'sdate': favData.sdate, 'edate':favData.edate, 'query_user':favData.query_user}
    axios.post(apiBaseURL + 'add_dqexplorer_favorite', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      callback(res)
    }).catch((reason: any) => {
      callback({})
    })
  }

  const deleteFavorite = (armid:string, favData:dqExplorerFavorite, callback: any) => {
    let query_data = {'armid': armid, 'datastreams': favData.datastreams, 'sdate': favData.sdate, 'edate':favData.edate, 'query_user':favData.query_user}
    axios.post(apiBaseURL + 'delete_dqexplorer_favorite', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      callback(res)
    }).catch((reason: any) => {
      console.log(reason)
      callback({})
    })
  }

  const authenticateUser = (username:string, password:string) => {

    dispatch(setIsAuthenticating(true))

    let url = apiBaseURL + '/ldap_auth';
    let headers = new Headers();
    //headers.append('Content-Type', 'text/json');
    headers.set('Authorization', 'Basic ' + Buffer.from(username + ":" + password).toString('base64'));

    fetch(url, {method:'GET',
      headers: headers,
     })
    .then(response => response.json())
    .then(json => {
      if(json.result === 'success'){
        let decoded = jwt_decode(json.token) as any
        let role = decoded.is_manager==true ? "Manager" : "Analyst"

        getAnalystAssignments(decoded.id, dqaWeekStart, dqaWeekEnd, () => {
          dispatch(setIsAuthenticated(true))
          dispatch(setCurrentUser({
            'firstName': decoded.first_name,
            'lastName': decoded.last_name,
            'armID': decoded.id,
            'username': decoded.username
          }))
          dispatch(setIsAuthenticating(false))
          dispatch(setLoginModalIsOpen(false))
          dispatch(setAuthenticationMessage("Authentication Successful"))

          localStorage.setItem('token', json.token)
        })

        
      }
      else if (json.result === 'invalid_credentials'){
        dispatch(setAuthenticationMessage("Invalid Credentials"))
        dispatch(setIsAuthenticating(false))
      }
      else {
        dispatch(setAuthenticationMessage("Authentication Server Error"))
        dispatch(setIsAuthenticating(false))
      }
    });
  }

  const sendHelpEmail = (email:string, comment:string, callback:any) => {
    let query_data = {
      'body': comment,
      'email': email,
      'reason': 'help',
    }

    axios.post(apiBaseURL + 'send_email', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      callback("success")
    }).catch((reason: any) => {
      callback("failure")
    })
    
  }

  const sendAlertEmail = (email:string, comment:string, callback:any) => {
    let query_data = {
      'body': comment,
      'email': email,
      'reason': 'help',
    }

    axios.post(apiBaseURL + 'send_email', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      callback("success")
    }).catch((reason: any) => {
      callback("failure")
    })
    
  }

  const getWikiLink = (instrument:string, callback:any) => {
    axios.post(apiBaseURL + 'get_dqexplorer_wiki_link', {'instrument':instrument}, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      callback(res.data)
    }).catch((reason: any) => {
      callback("")
    })
  }


  return (

    <div className="App">
      {isLoadingFromQS || !appLoaded
        ?
           <LoadingOverlay/> 
        :
          <div>
            <LoginModal 
              authenticateUser={authenticateUser}
            />
            <MenuBar 
              logOutUser={logOutUser}
            />
            <Switch>
{              <Route path="/userdashboard">
                <UserDashboard
                  saveFavorite={saveFavorite}
                  deleteFavorite={deleteFavorite}
                />
              </Route>}
              <Route exact path="/">
                <RequestQueue 
                  appLoaded={appLoaded}
                  fetchDatastreams={fetchDatastreams}
                  fetchDates={fetchDates}
                  fetchMetricsData={fetchMetricsData}
                  decomposeDatastream={decomposeDatastream}
                />
              </Route>
              <Route path="/metrics">
                <MetricsDisplay 
                  fetchPlots={fetchPlots}
                  decomposeDatastream={decomposeDatastream}
                  getWikiLink={getWikiLink}
                  sendAlertEmail={sendAlertEmail}
                  submitReprocessingJob={submitReprocessingJob}
                />
                <FloatingShareButton/>
                <FloatingFavoritesButton saveFavorite={saveFavorite}/>
              </Route>
            </Switch>
            <HelpFooter sendHelpEmail={sendHelpEmail}/>
          </div>
      }
    </div>

  );
}




export default App;
