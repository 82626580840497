import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import requestQueueReducer from '../features/requestQueue/requestQueueSlice';
import authReducer from '../features/menuBar/authSlice';
import userDashboardReducer from '../features/userDashboard/userDashboardSlice';

export const store = configureStore({
  reducer: {
    requestQueue: requestQueueReducer,
    auth: authReducer,
    userDashboard: userDashboardReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
