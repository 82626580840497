import React, { useState, useEffect, useRef, forwardRef } from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';
// import ReactSelect from 'react-select'
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import AsyncSelect from 'react-select/async';
import Switch from "react-switch";
import { isAfter, isBefore } from 'date-fns'
import { useTransition, animated } from "react-spring";
import DatePicker from 'react-datepicker';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSpinner, faEdit } from '@fortawesome/free-solid-svg-icons'

import 'react-datepicker/dist/react-datepicker.css';

import { useAppDispatch, useAppSelector } from '../../app/hooks';

import styles from './RequestQueue.module.css';

import { setQueuedDatastreams, 
    setMetricsData, 
    setQueryUserDB, 
    setDsMap,
    setSelectedQueryUser, 
    selectQueuedDatastreams, 
    selectQueryUserDB, 
    selectSelectedQueryUser, 
    selectQueryUserOptions,
    selectDsMap,
    setDsArray,
    selectDsArray 
} from './requestQueueSlice';

import { selectCurrentUser } from '../menuBar/authSlice';

import { datastreamRequestData } from '../../types'

type ReactSelectProps = React.ComponentProps<typeof ReactSelect>
const MultiSelect = (props:ReactSelectProps) => {
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[props.allOption, ...props.options!]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if ((selected as any)[selected.length - 1].value === props.allOption.value) {
              return props.onChange!([props.allOption, ...props.options!], event);
            }
            let result = [];
            if (selected.length === props.options!.length) {
              if (selected.includes(props.allOption)) {
                result = selected.filter(
                  (option:any) => option.value !== props.allOption.value
                );
              } else if (event.action === "select-option") {
                result = [props.allOption, ...props.options!];
              }
              return props.onChange!(result, event);
            }
          }

          return props.onChange!(selected, event);
        }}
      />
    );
  }

  return <ReactSelect {...props} />;
};

MultiSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

MultiSelect.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*"
  }
};

// REQUEST QUEUE MENU PRIMARY COMPONENT //
interface RequestQueueProps {
  appLoaded: boolean
  fetchDatastreams: any
  fetchDates: any
  fetchMetricsData: any
  decomposeDatastream: any
}

export function RequestQueue(props:RequestQueueProps) {

  const dispatch = useAppDispatch();

  // Create state vars and associated setters
  const [tempQueuedDatastreams, setTempQueuedDatastreams] = useState<datastreamRequestData[]>([]);
  const [metricsDataIsLoading, setMetricsDataIsLoading] = useState<boolean>(false);
  const [menuOptionsAreLoading, setMenuOptionsAreLoading] = useState<boolean>(false);
  const [componentLoaded, setComponentLoaded] = useState<boolean>(false);
  const [buttonText, setbuttonText] = useState<string>("Add to Queue");
  const [buttonClass, setbuttonClass] = useState<string>(styles.fadeIn);

  const [dsTree, setDsTree] = useState<any>()
  // const [dsArray, setDsArray] = useState<string[]>([])

  const [selectedDatastream, setSelectedDatastream] = useState<null|string>()
  const [selectedSite, setSelectedSite] = useState<null|string>()
  const [selectedDsCode, setSelectedDsCode] = useState<null|string>()
  const [selectedFacility, setSelectedFacility] = useState<null|string>()
  const [selectedLevel, setSelectedLevel] = useState<null|string>()

  const [selectedStartDate, setSelectedStartDate] = useState<null|Date>()
  const [selectedEndDate, setSelectedEndDate] = useState<null|Date>()
  const [dateOptions, setDateOptions] = useState<null|Date[]>()


  const dsArray = useAppSelector(selectDsArray)
  const currentUser = useAppSelector(selectCurrentUser)
  const queryUserDB = useAppSelector(selectQueryUserDB)
  const selectedQueryUser = useAppSelector(selectSelectedQueryUser)
  const queuedDatastreams = useAppSelector(selectQueuedDatastreams)
  const queryUserOptions = useAppSelector(selectQueryUserOptions)

  // Fetch datastreams to populate selection menu on component mount or user change
  useEffect(() => {
    if(props.appLoaded) {
      populateMenuOptions()
    }
  }, [props.appLoaded, queryUserDB, selectedQueryUser]); // Pass [] to only run once (on mount)

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
       isInitialMount.current = false;
    } else {
      setTempQueuedDatastreams([])

      dispatch(setQueuedDatastreams([]))
      dispatch(setMetricsData([]))
    }
  },[queryUserDB, selectedQueryUser])

  // Set request queue menu items to master list
  useEffect(() => {
    setTempQueuedDatastreams(queuedDatastreams)
  },[queuedDatastreams])


  const populateMenuOptions = () => {
    // dispatch(setQueuedDatastreams([]))

    setDsTree(null)
    dispatch(setDsArray([]))
    setSelectedDatastream(null)
    setSelectedSite(null)
    setSelectedDsCode(null)
    setSelectedFacility(null)
    setSelectedLevel(null)
    setSelectedStartDate(null)
    setSelectedEndDate(null)
    setDateOptions(null)

    setMenuOptionsAreLoading(true)

    props.fetchDatastreams(queryUserDB, (dsData:any) => {
      let tempDsTree:any = {}
      let tempDsArray = dsData['ds']

      for (let i=0; i<tempDsArray.length; i++){

          let ds_parts = props.decomposeDatastream(tempDsArray[i])
          let site = ds_parts["site"];
          let instrument = ds_parts["instrument"];
          let facility = ds_parts["facility"];
          let data_level = ds_parts["data_level"];

          
          if (!tempDsTree[site]) {tempDsTree[site] = {}};
          if (!tempDsTree[site][instrument]) tempDsTree[site][instrument] = {};
          if (!tempDsTree[site][instrument][facility]) tempDsTree[site][instrument][facility] = [];
          if (-1 === tempDsTree[site][instrument][facility].indexOf(data_level)) tempDsTree[site][instrument][facility].push(data_level);
      }

      setDsTree(tempDsTree)
      dispatch(setDsArray(tempDsArray))
      setMenuOptionsAreLoading(false)
    })
  }
      

  const addDatastreamToQueue = (datastreamData:datastreamRequestData) => {

    const isAlreadyInQueue = tempQueuedDatastreams.some(e => (e.name === datastreamData.name && e.sdate === datastreamData.sdate && e.edate === datastreamData.edate))

    if (!isAlreadyInQueue) {
      setTempQueuedDatastreams([ ...tempQueuedDatastreams, datastreamData])
      setbuttonText("Datastream Added")

      const fadeOutTimer = setTimeout(() => {
         setbuttonClass(styles.fadeOut)
      }, 500);

      const fadeInTimer = setTimeout(() => {
         setbuttonText("Add to Queue")
         setbuttonClass(styles.fadeIn)
      }, 700);

    }
    else {
      alert("This datastream & date range are already queued.")
    }
    
  }

  const removeDatastreamFromQueue = (datastream:string, sdate:string, edate:string) => {
    let tempDatastreamsList = [...tempQueuedDatastreams]
    let removeIndex = null

    for (let i=0; i<tempDatastreamsList.length; i++) {
      let currDs = tempDatastreamsList[i]

      if (currDs.name === datastream && currDs.sdate === sdate && currDs.edate === edate) {
        removeIndex = i
        break
      }
    }

    if (removeIndex != null) {
      tempDatastreamsList.splice(removeIndex, 1);
      setTempQueuedDatastreams(tempDatastreamsList)
    } 

  }

  const determineQueryUser = () => {

    if (currentUser !== null) {
      for (let i=0;i<queryUserOptions.length;i++) {
        if (queryUserOptions[i].value === currentUser.username) {
          return(currentUser.username)
        }
      }
    } 
    else {
      return(queryUserOptions[0].value)
    }
  }

  const handleQueryUserDBSwitch = () => {
    let selectedUser = determineQueryUser()
    if(tempQueuedDatastreams.length === 0) {
      dispatch(setQueryUserDB(!queryUserDB))
      dispatch(setSelectedQueryUser(selectedUser))
    } else {
      var confirm:any = window.confirm("This will clear the current request queue, are you sure?");
      if(confirm) {
        dispatch(setQueryUserDB(!queryUserDB))
        dispatch(setSelectedQueryUser(selectedUser))
      }
    }
  }

  const handleQueryUserSelectChange = (selectedOption: any, action: any) => {

    if(tempQueuedDatastreams.length === 0) {
      dispatch(setSelectedQueryUser(selectedOption.value))
    }
    else {
      var confirm:any = window.confirm("This will clear the current request queue, are you sure?");
      if(confirm) {
        dispatch(setSelectedQueryUser(selectedOption.value))
      }
    }
  }

  const handleMenuChange = (selectedOption: any, action: any, selectKey: string) => {
    let selectedValue = selectedOption.value
    let prevDatastream = selectedDatastream

    if (selectKey === 'datastream') {
      let ds_parts = props.decomposeDatastream(selectedValue)
      let site = ds_parts["site"];
      let instrument = ds_parts["instrument"];
      let facility = ds_parts["facility"];
      let data_level = ds_parts["data_level"];

      setSelectedDatastream(selectedValue)

      setSelectedSite(site)
      setSelectedDsCode(instrument)
      setSelectedFacility(facility)
      setSelectedLevel(data_level)

      if (prevDatastream !== selectedValue) {
        // setSelectedStartDate(null)
        // setSelectedEndDate(null)
        setDateOptions(null)
        getDates(selectedValue)
      }

    }
    if (selectKey === "site") {
      setSelectedSite(selectedValue)

      setSelectedDatastream(null)
      setSelectedDsCode(null)
      setSelectedFacility(null)
      setSelectedLevel(null)
      // setSelectedStartDate(null)
      // setSelectedEndDate(null)
      setDateOptions(null)

    }
    else if (selectKey === "dscode") {
      let tempFacilities = Object.keys(dsTree![selectedSite!][selectedValue])
      let tempSelectedFacility = tempFacilities[0]

      let tempLevels = dsTree![selectedSite!][selectedValue][tempSelectedFacility]
      let tempSelectedLevel = tempLevels[0]

      let tempSelectedDatastream = selectedSite + selectedValue + tempSelectedFacility + '.' + tempSelectedLevel

      setSelectedDatastream(tempSelectedDatastream)
      setSelectedDsCode(selectedValue)
      setSelectedFacility(tempSelectedFacility)
      setSelectedLevel(tempSelectedLevel)

      if (prevDatastream !== tempSelectedDatastream) {
        // setSelectedStartDate(null)
        // setSelectedEndDate(null)
        setDateOptions(null)
        getDates(tempSelectedDatastream)
      }

    }
    else if (selectKey === "dscode") {
      let tempFacilities = Object.keys(dsTree![selectedSite!][selectedValue])
      let tempSelectedFacility = tempFacilities[0]

      let tempLevels = dsTree![selectedSite!][selectedValue][tempSelectedFacility]
      let tempSelectedLevel = tempLevels[0]

      let tempSelectedDatastream = selectedSite + selectedValue + tempSelectedFacility + '.' + tempSelectedLevel

      setSelectedDatastream(tempSelectedDatastream)
      setSelectedDsCode(selectedValue)
      setSelectedFacility(tempSelectedFacility)
      setSelectedLevel(tempSelectedLevel)

      if (prevDatastream !== tempSelectedDatastream) {
        // setSelectedStartDate(null)
        // setSelectedEndDate(null)
        setDateOptions(null)
        getDates(tempSelectedDatastream)
      }
    }
    else if (selectKey === "facility") {
      
      let tempLevels = dsTree![selectedSite!][selectedDsCode!][selectedValue]
      let tempSelectedLevel = tempLevels[0]

      let tempSelectedDatastream = selectedSite! + selectedDsCode + selectedValue + '.' + tempSelectedLevel

      setSelectedDatastream(tempSelectedDatastream)
      setSelectedFacility(selectedValue)
      setSelectedLevel(tempSelectedLevel)

      if (prevDatastream !== tempSelectedDatastream) {
        // setSelectedStartDate(null)
        // setSelectedEndDate(null)
        setDateOptions(null)
        getDates(tempSelectedDatastream)
      }
    }
    else if (selectKey === "level") {
      let tempSelectedDatastream = selectedSite! + selectedDsCode + selectedFacility + '.' + selectedValue

      setSelectedDatastream(tempSelectedDatastream)
      setSelectedLevel(selectedValue)

      if (prevDatastream !== tempSelectedDatastream) {
        // setSelectedStartDate(null)
        // setSelectedEndDate(null)
        setDateOptions(null)
        getDates(tempSelectedDatastream)
      }
    }
  }

  const handleDateChange = (startDate:Date, endDate:Date) => {

      startDate = startDate || selectedStartDate
      endDate = endDate || selectedEndDate

      if (isBefore(endDate, selectedStartDate!)) {
        startDate = endDate
      } else if (isAfter(startDate, selectedEndDate!)) {
        endDate = startDate
      }

      setSelectedStartDate(startDate)
      setSelectedEndDate(endDate)
  }


  const getDates = (datastream: string) => {

    props.fetchDates(datastream, (dates:string[]) => {
      let jsDates = []
      for(let i=0;i<dates.length;i++){
        jsDates.push(moment(dates[i]).startOf('day').toDate())
      }

      setDateOptions(jsDates)

      if (selectedStartDate === null || selectedEndDate === null) {
        setSelectedStartDate(jsDates[jsDates.length-1])
        setSelectedEndDate(jsDates[jsDates.length-1])
      }
    })

  }

  return(
    <div className={styles.RequestQueueContainer}>
        <Grid fluid>
            <Row>
                <Col sm={12} md={12} lg={6}>
                    <div className={styles.RequestQueueMenuContainer}>
                        <div className={styles.RequestQueueHeader}>
                            <p className={styles.RequestQueueHeaderTitle}>Request Selection</p>

                            <div className={styles.RequestQueueHeaderSwitchContainer}>
                              <p>Query user database?</p>
                              <div style={{top:7, position:'relative', display:'inline-block'}}>
                                <Switch onChange={handleQueryUserDBSwitch} checked={queryUserDB} />
                              </div>
                              { queryUserDB ?
                                <div className={styles.RequestQueueHeaderSelectContainer}>
                                  <ReactSelect 
                                    value={{'value':selectedQueryUser, 'label':selectedQueryUser}}
                                    className={styles.RequestQueueHeaderSelect} 
                                    options={queryUserOptions}
                                    onChange={handleQueryUserSelectChange} 
                                  />
                                </div>
                                :
                                null
                              }
                            </div>

                        </div>
                        <div className={styles.RequestQueueMenu}>
                            <RequestMenu 
                              buttonText={buttonText}
                              buttonClass={buttonClass}
                              dsArray={dsArray}
                              dsTree={dsTree}
                              selectedDatastream={selectedDatastream}
                              selectedSite={selectedSite}
                              selectedDsCode={selectedDsCode}
                              selectedFacility={selectedFacility}
                              selectedLevel={selectedLevel}
                              selectedStartDate={selectedStartDate}
                              selectedEndDate={selectedEndDate}
                              dateOptions={dateOptions}
                              addDatastreamToQueue={addDatastreamToQueue}
                              handleMenuChange={handleMenuChange}
                              handleDateChange={handleDateChange}
                              menuOptionsAreLoading={menuOptionsAreLoading}
                              metricsDataIsLoading={metricsDataIsLoading}
                            />
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={12} lg={6}>
                    <div className={styles.RequestQueueMenuContainer}>
                        <div className={styles.RequestQueueHeader}>
                            <p className={styles.RequestQueueHeaderTitle}>Request Queue</p>
                        </div>
                        <div className={styles.RequestQueueMenu}>
                          <QueueMenu 
                            fetchMetricsData={props.fetchMetricsData}
                            removeDatastreamFromQueue={removeDatastreamFromQueue}
                            metricsDataIsLoading={metricsDataIsLoading}
                            setMetricsDataIsLoading={setMetricsDataIsLoading}
                            tempQueuedDatastreams={tempQueuedDatastreams}
                            fetchDates={props.fetchDates}
                            setTempQueuedDatastreams={setTempQueuedDatastreams}
                          />
                        </div>
                    </div>
                </Col>
              
            </Row>
        </Grid>
    </div>
  )
}




// QUEUE MENU (RIGHT/BOTTOM) COMPONENT //
interface QueueMenuProps {
    fetchMetricsData: any
    removeDatastreamFromQueue: any
    metricsDataIsLoading: boolean
    setMetricsDataIsLoading: any
    tempQueuedDatastreams: datastreamRequestData[]
    fetchDates: any
    setTempQueuedDatastreams: any
}

const QueueMenu = (props:QueueMenuProps) => {

  const history = useHistory()
  const dispatch = useAppDispatch();

  // const queuedDatastreams = useAppSelector(selectQueuedDatastreams);

  const handleSubmit = () => {
    props.setMetricsDataIsLoading(true)
    props.fetchMetricsData(props.tempQueuedDatastreams, (metricsData:any) => {

      dispatch(setMetricsData(metricsData))
      dispatch(setQueuedDatastreams(props.tempQueuedDatastreams))
      props.setMetricsDataIsLoading(false)
      history.push('/metrics')
    })
  }

  let listTransitions = useTransition(props.tempQueuedDatastreams, {
    from: { opacity: 0 as any, maxHeight: 0 as any, marginTop: 0 as any },
    enter: [{ maxHeight: 80, marginTop: 5 }, { opacity: 1 }],
    leave: [{ opacity: 0 }, { maxHeight: 0, marginTop: 0 }],
    config: { tension: 200, duration: 100 },
    keys: props.tempQueuedDatastreams.map((item, index) => index)
  });

  let listIndex = -1
  const fadeInListItems = listTransitions((style, item) => {
    listIndex +=1
    return (
      <animated.div style={style}>
        <QueueMenuItem listIndex={listIndex} tempQueuedDatastreams={props.tempQueuedDatastreams} setTempQueuedDatastreams={props.setTempQueuedDatastreams} fetchDates={props.fetchDates} removeDatastreamFromQueue={props.removeDatastreamFromQueue} datastream={item.name} sdate={item.sdate} edate={item.edate}/>
      </animated.div>
    );
  });

  return (
    <div className={styles.QueueMenuContainer}>
        <div className={styles.QueueMenuItemContainer}>
          <Scrollbars
            style={{ width: '100%', height: '100%'}}
            renderThumbVertical={props => <div {...props} className={styles.thumbVertical}/>}
          >
            {fadeInListItems}
          </Scrollbars>
        </div>
        <div className={styles.RequestQueueButtonContainer}>
          <button 
            disabled={props.tempQueuedDatastreams.length === 0 || props.metricsDataIsLoading} 
            onClick={handleSubmit} className={styles.RequestQueueButton}> 
            Submit 
            {props.metricsDataIsLoading ? <FontAwesomeIcon style={{marginLeft:10}} className={"fa-spin"} icon={faSpinner} /> : null}
          </button>
        </div>
    </div>
  )
}

interface QueueMenuItemProps {
    datastream: string
    sdate: string
    edate: string
    removeDatastreamFromQueue: any
    fetchDates: any
    setTempQueuedDatastreams: any
    tempQueuedDatastreams: datastreamRequestData[]
    listIndex: number
}

const QueueMenuItem = (props:QueueMenuItemProps) => {

  const [isFetchingDates, setIsFetchingDates] = useState<boolean>(false)
  const [dateOptions, setDateOptions] = useState<null|Date[]>()
  const [selectedSDate, setSelectedSDate] = useState<Date>(moment(props.sdate, "YYYYMMDD").toDate())
  const [selectedEDate, setSelectedEDate] = useState<Date>(moment(props.edate, "YYYYMMDD").toDate())

  const getQueueItemDates = (datastream:string, callback:any) => {
    props.fetchDates(datastream, (dates:string[]) => {
      let jsDates = []
      for(let i=0;i<dates.length;i++){
        jsDates.push(moment(dates[i]).startOf('day').toDate())
      }
      setDateOptions(jsDates)
    })
    callback()
  }

  const handleDateEditClick = (callback:any) => {
    getQueueItemDates(props.datastream, () => {
      callback()
    }) 
  }

  const handleDateRangeChange = (dateRange:any) => {

    // have to do this because props.tempQueuedDatastreams is readonly when returned from Redux store
    let tempDsList = JSON.parse(JSON.stringify(props.tempQueuedDatastreams)); 

    if (dateRange[0] !== null) {
      tempDsList[props.listIndex].sdate = dateRange[0].toISOString().split('T')[0].replace(/-/g, '')

      if (dateRange[0] > props.edate) {
        tempDsList[props.listIndex].edate = dateRange[0].toISOString().split('T')[0].replace(/-/g, '')
      }

    }
    if (dateRange[1] !== null) {
      tempDsList[props.listIndex].edate = dateRange[1].toISOString().split('T')[0].replace(/-/g, '')

      if (dateRange[1] < props.sdate) {
        tempDsList[props.listIndex].sdate = dateRange[1].toISOString().split('T')[0].replace(/-/g, '')
      }
    }

    console.log(dateRange[0], dateRange[1])
    
    setSelectedSDate(dateRange[0])
    setSelectedEDate(dateRange[1])
    props.setTempQueuedDatastreams(tempDsList)
  }

  const highlightWithRanges = [
  {
    "react-datepicker__day--highlighted-custom-2": [],
  }] as any

  if (dateOptions !== null && dateOptions !== undefined) {
    for(let date of dateOptions!) {
      highlightWithRanges[0]["react-datepicker__day--highlighted-custom-2"].push(date)
    }
  }

  const ExampleCustomInput = forwardRef((props, ref) => (
    // @ts-ignore
    <FontAwesomeIcon className={styles.QueueMenuItemEdit} onClick={() => {handleDateEditClick(props.onClick)}} ref={ref} icon={faEdit}>
    </FontAwesomeIcon>
  ));

  return (
      <div className={styles.QueueMenuItem}>
        <div className={styles.QueueMenuItemLeftContainer}>
          <p className={styles.QueueMenuItemName}>{props.datastream}</p>

          <p className={styles.QueueMenuItemDate}>
            {props.sdate} - {props.edate}
             <DatePicker
                selected={selectedSDate}
                onChange={handleDateRangeChange}
                customInput={<ExampleCustomInput />}
                startDate={selectedSDate}
                endDate={selectedEDate}
                selectsRange
                highlightDates={highlightWithRanges}
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
              />
          </p>
        </div>




        <div className={styles.QueueMenuItemRightContainer}>
          <div onClick={(e)=>{props.removeDatastreamFromQueue(props.datastream, props.sdate, props.edate)}} className={styles.QueueMenuItemRemove}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </div>
    )
}




// REQUEST MENU (LEFT/TOP) COMPONENT //
interface RequestMenuProps {
  buttonText:string
  buttonClass:string
  dsArray:string[]
  dsTree:any
  selectedDatastream?:null|string
  selectedSite?:null|string
  selectedDsCode?:null|string
  selectedFacility?:null|string
  selectedLevel?:null|string
  selectedStartDate?:null|Date
  selectedEndDate?:null|Date
  dateOptions?:null|Date[]
  addDatastreamToQueue:any
  handleMenuChange:any
  handleDateChange:any
  menuOptionsAreLoading:boolean
  metricsDataIsLoading:boolean
}

const RequestMenu = (props:RequestMenuProps) => {

  const dsMap = useAppSelector(selectDsMap)

  const formatDSCodeOptions = () => {

    let dsCodeOptionObj = {'No Class Found':[]} as any

    Object.keys(props.dsTree![selectedSite!.value!]!).map((s, i) => {

      if (dsMap !== null && dsMap !== undefined) {
        if (s in dsMap) {
          let inst_class = dsMap[s]

          if (!(inst_class in dsCodeOptionObj)) {
            dsCodeOptionObj[inst_class] = []
          }
          dsCodeOptionObj[inst_class].push(s)
        }
        else {
          dsCodeOptionObj['No Class Found'].push(s)
        }
      }
      else {
        dsCodeOptionObj['No Class Found'].push(s)
      }
    })

    let dsCodeOptionArr:any[] = []

    Object.keys(dsCodeOptionObj).map((c, i) => {
      let options = dsCodeOptionObj[c].map((s:any, i:number) => ({ value: s, label: s }))
      let optObj = {'label':c, options:options}
      dsCodeOptionArr.push(optObj)
    })

    return dsCodeOptionArr
  }

  const dsOptions = (props.dsArray === null || props.dsArray === undefined) ? [] : props.dsArray!.map((s, i) => ({ value: s, label: s }))
  const selectedDatastream = (props.selectedDatastream === undefined || props.selectedDatastream === null) ? null : {value: props.selectedDatastream, label: props.selectedDatastream}

  const siteOptions = (props.dsTree === null || props.dsTree === undefined) ? [] : Object.keys(props.dsTree!).map((s, i) => ({ value: s, label: s }))
  const selectedSite = (props.selectedSite === undefined || props.selectedSite === null) ? null : {value: props.selectedSite, label: props.selectedSite}

  const dsCodeOptions = (selectedSite === null ) ? [] : formatDSCodeOptions()
  const selectedDsCode = (props.selectedDsCode === undefined || props.selectedDsCode === null) ? null : {value: props.selectedDsCode, label: props.selectedDsCode}

  const facilityOptions = (selectedSite === null || selectedDsCode === null) ? [] : Object.keys(props.dsTree![selectedSite.value!][selectedDsCode.value!]!).map((s, i) => ({ value: s, label: s }))
  const selectedFacility = (props.selectedFacility === undefined || props.selectedFacility === null) ? null : {value: props.selectedFacility, label: props.selectedFacility}

  const levelOptions = (selectedSite === null || selectedDsCode === null || selectedFacility === null) ? [] : props.dsTree![selectedSite.value!][selectedDsCode.value!][selectedFacility.value!].map((s:string, i:number) => ({ value: s, label: s }))
  const selectedLevel = (props.selectedLevel === undefined || props.selectedLevel === null) ? null : {value: props.selectedLevel, label: props.selectedLevel}

  const loadDSOptions = (inputValue:any, callback:any) => {
    if(inputValue !== ''){
      setTimeout(() => {
        callback(dsOptions.filter(i => i.label.toLowerCase().startsWith(inputValue.toLowerCase())));
      }, 1000);
    }
    else{
       callback([])
    }
  }



  return (
      <div className={styles.RequestMenuContainer}>

          <AsyncSelect 
            className={styles.RequestMenuSelect}
            cacheOptions 
            loadOptions={loadDSOptions}
            noOptionsMessage={() => 'Start typing to see options.'}
            value={selectedDatastream} 
            disabled={props.metricsDataIsLoading}
            placeholder={dsOptions.length === 0 ? "--" : "Select Datastream..."}
            isLoading={props.menuOptionsAreLoading}
            onChange={(selectedOptions:any, action:any) => {props.handleMenuChange(selectedOptions, action, 'datastream')}} 
          />
          <div className={styles.RequestMenuDivider}>
              <p>OR</p>
          </div>
          <ReactSelect 
            className={styles.RequestMenuSelect} 
            options={siteOptions}
            value={selectedSite}
            disabled={siteOptions.length === 0 || props.metricsDataIsLoading} 
            placeholder={siteOptions.length === 0 ? "--" : "Select Site..."}
            isLoading={props.menuOptionsAreLoading}
            onChange={(selectedOptions, action) => {props.handleMenuChange(selectedOptions, action, 'site')}} 
          />
          <ReactSelect 
            className={styles.RequestMenuSelect} 
            options={dsCodeOptions} 
            value={selectedDsCode}
            disabled={dsCodeOptions.length === 0 || props.metricsDataIsLoading} 
            placeholder={dsCodeOptions.length === 0 ? "--" : "Select Datastream Code..."}
            onChange={(selectedOptions, action) => {props.handleMenuChange(selectedOptions, action, 'dscode')}} 
          />
          <MultiSelect 
            className={styles.RequestMenuSelect} 
            options={facilityOptions} 
            value={selectedFacility}
            disabled={facilityOptions.length === 0 || props.metricsDataIsLoading} 
            placeholder={facilityOptions.length === 0 ? "--" : "Select Facility..."}
            onChange={(selectedOptions, action) => {props.handleMenuChange(selectedOptions, action, 'facility')}} 
          />
          <MultiSelect 
            className={styles.RequestMenuSelect} 
            options={levelOptions} 
            value={selectedLevel}
            disabled={levelOptions.length === 0 || props.metricsDataIsLoading} 
            placeholder={levelOptions.length === 0 ? "--" : "Select Facility..."}
            onChange={(selectedOptions, action) => {props.handleMenuChange(selectedOptions, action, 'level')}} 
          />

          <div className={styles.RequestMenuSingleDivider}></div>
          <DateRange dateOptions={props.dateOptions} selectedStartDate={props.selectedStartDate} selectedEndDate={props.selectedEndDate} handleDateChange={props.handleDateChange}/>

          <div className={styles.RequestQueueButtonContainer}>
            <button 
              onClick={() => {props.addDatastreamToQueue({'name':selectedDatastream!.value, 'sdate':props.selectedStartDate!.toISOString().split('T')[0].replace(/-/g, ''), 'edate':props.selectedEndDate!.toISOString().split('T')[0].replace(/-/g, '')})}} 
              className={styles.RequestQueueButton}
              disabled={ (props.selectedStartDate === null || props.selectedStartDate === undefined) || (props.selectedEndDate === null || props.selectedEndDate === undefined) || props.metricsDataIsLoading}> 
                <div className={props.buttonClass}>{props.buttonText}</div>
            </button>
          </div>
      </div>
  )
    
}


interface DateRangeProps {
  selectedStartDate?:null|Date
  selectedEndDate?:null|Date
  dateOptions?:null|Date[]
  handleDateChange: any
}

const DateRange = (props:DateRangeProps) => {

  const highlightWithRanges = [
  {
    "react-datepicker__day--highlighted-custom-2": [],
  }] as any

  if (props.dateOptions !== null && props.dateOptions !== undefined) {
    for(let date of props.dateOptions!) {
      highlightWithRanges[0]["react-datepicker__day--highlighted-custom-2"].push(date)
    }
  }

  const isPlotAvailableForDate = (caldate:Date) => {
    for(let date of props.dateOptions!) {
      if(caldate.getTime() === date.getTime()){
        return true
      }
    }
    return false
  }

  const handleChangeStart = (startDate: any) => {
    props.handleDateChange(startDate,null)
  }
  const handleChangeEnd = (endDate: any) =>  {
    props.handleDateChange(null,endDate)
  }

  return (
    <div className={styles.RequestQueueDateRangeContainer}>


      <div className={styles.RequestQueueDatePickerContainer}>
        <DatePicker
          dateFormat= "yyyy-MM-dd"
          placeholderText="Start Date"
          className={styles.RequestQueueDatePicker}
          wrapperClassName={styles.DatePickerWrapper}
          highlightDates={highlightWithRanges}
          // filterDate={isPlotAvailableForDate}
          disabled={props.dateOptions===null || props.dateOptions===undefined || props.dateOptions.length===0}
          // minDate={this.props.minDate}
          // maxDate={this.props.maxDate}
          maxDate={new Date()}
          selected={props.selectedStartDate}
          selectsStart
          startDate={props.selectedStartDate}
          endDate={props.selectedEndDate}
          onChange={handleChangeStart}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          disabledKeyboardNavigation
        />
      </div>
      <div className={styles.RequestQueueDatePickerContainer}>
        <DatePicker
          dateFormat= "yyyy-MM-dd"
          placeholderText="End Date"
          className={styles.RequestQueueDatePicker}
          wrapperClassName={styles.DatePickerWrapper}
          highlightDates={highlightWithRanges}
          // filterDate={isPlotAvailableForDate}
          disabled={props.dateOptions===null || props.dateOptions===undefined || props.dateOptions.length===0}
          // minDate={this.props.minDate}
          // maxDate={this.props.maxDate}\
          maxDate={new Date()}
          selected={props.selectedEndDate}
          selectsEnd
          startDate={props.selectedStartDate}
          endDate={props.selectedEndDate}
          onChange={handleChangeEnd} 
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          disabledKeyboardNavigation
        />
      </div>

    </div>
  )
  
}

