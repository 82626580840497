import React, { useState, Component } from 'react';
import NewWindow from 'react-new-window'
import { Tooltip, withTooltip } from 'react-tippy';

import 'react-tippy/dist/tippy.css'
import styles from './FieldMetricsDisplay.module.css';


interface FieldMetricsDisplayProps {
    onUnload: any,
    fieldData: any,
    columnHeaders: string[],
    columnUnits: string,
    datastreamName: string,
    dateTimeString: string
}
export function FieldMetricsDisplay(props:FieldMetricsDisplayProps) {

	return(
		<NewWindow title={props.fieldData['field_name'] + " field metrics"} center="screen" features={{'width':1000, 'height': 800, 'top':0, 'left':0}} onUnload={props.onUnload}>
			<div className={styles.FieldMetricsTableContainer}>
				<div>
					<p className={styles.FieldMetricsTableVarLabel}><b>Variable Name: {props.fieldData['field_name']}</b></p>
                    <p className={styles.FieldMetricsTableDSLabel}>{props.datastreamName} : {props.dateTimeString}</p>
				</div>
		    	<FieldMetricsTable fieldData={props.fieldData} columnHeaders={props.columnHeaders} columnUnits={props.columnUnits} />
                <hr className={styles.RoundedDivider}/>
                <FieldAttributesTable attributeData={props.fieldData['attributes']}/>
	    	</div>
	  	</NewWindow>
	)
}


interface FieldMetricsTableProps {
    fieldData: any,
    columnHeaders: string[],
    columnUnits: string
}
const FieldMetricsTable = (props:FieldMetricsTableProps) => {


	return (
		<table className={styles.FieldMetricsTable}>
			<colgroup>
                    <col style={{width:'30%'}} />
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        <th colSpan={props.columnHeaders.length}>{props.columnUnits} </th>
                    </tr>
                    <tr>
                        <th></th>
                        {
                            props.columnHeaders.map((e, i) => {
                                return(
                                    <th className={styles.FieldMetricsTableHeaderLabelCell}> 
                                        {e} 
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.fieldData['qc_tests'].map((test:any, i:number) => {
                            return(
                            	<FieldMetricsTableRow testData={test}/>
                            )
                        })
                    }
                </tbody>
		</table>
	)
}


const metricsAssessmentColorMap = new Map<string, string>(); 
metricsAssessmentColorMap.set('Multiple', '#59f')
metricsAssessmentColorMap.set('Bad', '#f77')
metricsAssessmentColorMap.set('Indeterminate', '#ff7')
metricsAssessmentColorMap.set('Missing', '#d4d4d4')
metricsAssessmentColorMap.set('Not_Available', '#ffc488')
metricsAssessmentColorMap.set('Power_Outage', '#8C4C73')
metricsAssessmentColorMap.set('Tip_Curve', '#c6ffaa')
metricsAssessmentColorMap.set('Good', '#afa')

interface FieldMetricsTableRowProps {
    testData: any,
}
const FieldMetricsTableRow = (props:FieldMetricsTableRowProps) => {
	let tooltipSectionColor = metricsAssessmentColorMap.get(props.testData['assessment'])
    let bitNum = null

    if (props.testData['test_name'].includes("bit")) {
        let nameParts = props.testData['test_name'].split('_')
        bitNum = nameParts[0] + " " + nameParts[1]
    }

	return(
		<tr>
			<td className={styles.FieldMetricsTableRowLabel}>
                <div style={{'backgroundColor': tooltipSectionColor, 'border': `1px solid ${tooltipSectionColor}`}} className={styles.FieldMetricsTableRowIndicator}></div>
                <b>{bitNum ? bitNum + " (" + props.testData['assessment'] + ")" : props.testData['assessment']}</b>
                <span className={styles.horizTooltip}>{props.testData['long_name']}</span>
            </td>

			{props.testData['percent'].map((percent:number, i:number) => {
				

        	 	if(percent > 0){
        	 		return (      
        	 			<td className={styles.FieldMetricsTableCell} style={{'background':tooltipSectionColor}}><span className={styles.tooltip}>{`${percent}%: ${props.testData['sample'][i]}`}</span></td>
        	 		)
        	 	} else {
        	 		return (
        	 			<td></td>
        	 		)
        	 	}
        	})}

		</tr>                      	
	)
}

interface FieldAttributesTableProps {
    attributeData: any,
}
const FieldAttributesTable = (props:FieldAttributesTableProps) => {
    console.log(Object.keys(props.attributeData))
    return(
        <div className={styles.FieldAttributesTableContainer}>
            <p className={styles.FieldAttributesTableLabel}>Field Attributes</p>

            <div>
                {Object.keys(props.attributeData).map((attrName:string, i:number) => {
                    return(<p className={styles.FieldAttributesLabel}><b>{attrName}:</b> {props.attributeData[attrName]}</p>)
                })}
            </div>
        </div>
    )
}