import React, { useState, Component, useEffect } from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  setLoginModalIsOpen,
  selectCurrentUser
} from './authSlice';

import { selectMetricsData } from '../requestQueue/requestQueueSlice';

import styles from './MenuBar.module.css';
import logo from '../../img/ARM_Logo_2017reverse.png';



interface MenuBarProps {
  logOutUser: any,
}

export function MenuBar(props: MenuBarProps) {

	const dispatch = useAppDispatch();

	const currentUser = useAppSelector(selectCurrentUser);
	const metricsData = useAppSelector(selectMetricsData)

	const [currentUTCTime, setCurrentUTCTime] = useState<String>(moment.utc().format())

	const openLoginModal = () => {
		dispatch(setLoginModalIsOpen(true))
	}

	useEffect(() => {
	  const interval = setInterval(() => {
	    setCurrentUTCTime(moment.utc().format())
	  }, 1000);
	  return () => clearInterval(interval);
	}, []);

	return (
		<div style={{position: "sticky", top: 0, zIndex: 9999}}>
			<div className={styles.MenuBarUTCTimeContainer}>
				<p>UTC time: {currentUTCTime}</p>
			</div>
			<div className={styles.MenuBarContainer}>
				<div className={styles.MenuBarTopContainer}>

					<div className={styles.MenuBarLogoContainer}>
						<div className={styles.MenuBarLogoImgContainer}>
							<img className={styles.MenuBarLogo} src={logo}/>
						</div>
						<h3 className={styles.MenuBarLogoHeader}>DQ Explorer</h3>
					</div>
					
					{currentUser === null ?
						<p className={styles.MenuBarUsernameText}>Welcome, guest</p>
						:
						<p className={styles.MenuBarUsernameText}>Welcome, {currentUser.firstName}</p>
					}
				</div>
				<div className={styles.MenuBarBottomContainer}>
					<div className={styles.MenuBarNavLinkContainer}>
						<NavLink className={styles.MenuBarNavLink} activeClassName={styles.MenuBarNavLinkActive} to="/" exact>
							Request Menu
						</NavLink>
					</div>
					{ metricsData.length !== 0 ?
						<div className={styles.MenuBarNavLinkContainer}>
							<NavLink className={styles.MenuBarNavLink} activeClassName={styles.MenuBarNavLinkActive} to="/metrics" exact>
								Metrics
							</NavLink>
						</div>
					:
						null
					}
					
					{currentUser === null ?
						null
					:
						<div className={styles.MenuBarNavLinkContainer}>
							<NavLink className={styles.MenuBarNavLink} activeClassName={styles.MenuBarNavLinkActive} to="/userdashboard" exact>
								Favorites
							</NavLink>
						</div>
					}
					{currentUser === null ?
						<button onClick={openLoginModal} className={styles.MenuBarButton}>Sign In</button>
					:
						<button onClick={props.logOutUser} className={styles.MenuBarButton}>Sign Out</button>
					}
				</div>

			</div>
		</div>
	)
}

// const LoginLogoutButton = () => {

// 	return (
// 		<div className={styles.MenuBarButtonContainer}>
			
			
// 		</div>
// 	)
// }