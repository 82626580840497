import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { dqExplorerFavorite } from '../../types'


export interface UserDashboardState {
  analystAssignments: string[][] | null,
  analystAssignmentCompletion: boolean[] | null,
  userFavorites: dqExplorerFavorite[],
  dqaWeekStart: string,
  dqaWeekEnd: string,
}

const initialState: UserDashboardState = {
    analystAssignments: null,
    analystAssignmentCompletion: null,
    userFavorites: [],
    dqaWeekStart: "",
    dqaWeekEnd: ""
};

export const UserDashboardSlice = createSlice({
  name: 'userDashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAnalystAssignments: (state, action: PayloadAction<any>) => {
      state.analystAssignments = action.payload
    },
    setAnalystAssignmentCompletion: (state, action: PayloadAction<any>) => {
      state.analystAssignmentCompletion = action.payload
    },
    setUserFavorites: (state, action: PayloadAction<any>) => {
      state.userFavorites = action.payload
    },
    setDqaWeekStart: (state, action: PayloadAction<any>) => {
      state.dqaWeekStart = action.payload
    },
    setDqaWeekEnd: (state, action: PayloadAction<any>) => {
      state.dqaWeekEnd = action.payload
    }
  }
});

export const { setAnalystAssignments, setAnalystAssignmentCompletion, setUserFavorites, setDqaWeekStart, setDqaWeekEnd } = UserDashboardSlice.actions;

export const selectAnalystAssignments = (state: RootState) => state.userDashboard.analystAssignments;
export const selectAnalystAssignmentCompletion = (state: RootState) => state.userDashboard.analystAssignmentCompletion;
export const selectUserFavorites = (state: RootState) => state.userDashboard.userFavorites;
export const selectDqaWeekStart = (state: RootState) => state.userDashboard.dqaWeekStart;
export const selectDqaWeekEnd = (state: RootState) => state.userDashboard.dqaWeekEnd;

export default UserDashboardSlice.reducer; 