import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

import { dqExplorerUser } from '../../types'

export interface AuthState {
  loginModalIsOpen: boolean,
  isAuthenticating: any,
  isAuthenticated: boolean,
  authenticationMessage: string,
  currentUser: null|dqExplorerUser
}

const initialState: AuthState = {
    loginModalIsOpen: false,
    isAuthenticating: false,
    isAuthenticated: false,
    authenticationMessage: '',
    currentUser: null
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoginModalIsOpen: (state, action: PayloadAction<any>) => {
      state.loginModalIsOpen = action.payload
    },
    setIsAuthenticating: (state, action: PayloadAction<any>) => {
      state.isAuthenticating = action.payload
    },
    setIsAuthenticated: (state, action: PayloadAction<any>) => {
      state.isAuthenticated = action.payload
    },
    setAuthenticationMessage: (state, action: PayloadAction<any>) => {
      state.authenticationMessage = action.payload
    },
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload
    },
  }
});

export const { setLoginModalIsOpen, setIsAuthenticating, setIsAuthenticated, setAuthenticationMessage, setCurrentUser } = AuthSlice.actions;

export const selectLoginModalIsOpen = (state: RootState) => state.auth.loginModalIsOpen;
export const selectIsAuthenticating = (state: RootState) => state.auth.isAuthenticating;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectAuthenticationMessage = (state: RootState) => state.auth.authenticationMessage;
export const selectCurrentUser = (state: RootState) => state.auth.currentUser;

export default AuthSlice.reducer; 