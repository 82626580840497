import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt, faClipboard, faCheck } from '@fortawesome/free-solid-svg-icons'

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectQueuedDatastreams, 
    selectQueryUserDB, 
    selectSelectedQueryUser 
} from '../requestQueue/requestQueueSlice';

import {
  selectCurrentUser,
} from '../menuBar/authSlice';

import styles from './FloatingShareButton.module.css';


export const FloatingShareButton = () => {

    const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
    const [shareLink, setShareLink] = useState<string>("");
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const queuedDatastreams = useAppSelector(selectQueuedDatastreams)
    const currentUser = useAppSelector(selectCurrentUser);
    const queryUserDB = useAppSelector(selectQueryUserDB)
    const selectedQueryUser = useAppSelector(selectSelectedQueryUser)

    const genShareLink = () => {
        let shareLinkStr = window.location.origin + "/dq-explorer/#/?"

        if (queryUserDB && selectedQueryUser !== null) {
            shareLinkStr += 'u=' + selectedQueryUser + '&'
        }
        else {
            shareLinkStr += 'u=' + 'dqmgr' + '&'
        }
        

        for(let i=0; i<queuedDatastreams.length; i++) {
            shareLinkStr += 'd=' + queuedDatastreams[i].name + '&'
            shareLinkStr += 's=' + queuedDatastreams[i].sdate + '&'
            shareLinkStr += 'e=' + queuedDatastreams[i].edate + '&'
        }

        return shareLinkStr
    }

    const handleShareButtonClick = () => {
        let shareLinkStr = genShareLink()
        setShareLink(shareLinkStr)

        showModal()
        console.log(shareLinkStr)
    }

    const showModal = () => {
      setIsShowingModal(true)
    }
    const handleClose = () => {
      setIsShowingModal(false)
      setIsCopied(false)
      setShareLink('')
    }

    console.log(isCopied)
    return (
        <div>
          <div onClick={handleShareButtonClick} className={styles.FloatingShareButtonContainer}>
            <FontAwesomeIcon className={styles.FloatingShareButton} icon={faShareAlt} />
          </div>
          <Modal open={isShowingModal} onClose={handleClose} classNames={{ overlay: styles.ModalOverlay, modal: styles.Modal, closeButton:styles.ModalCloseButton, closeIcon:styles.ModalCloseIcon}} center>
            <div style={{minWidth:320}}>
              <div >
                <h3 style={{margin:0, paddingBottom:20}}>Metrics Share Link</h3>
              </div>
              <div className={styles.ShareLinkContainer}>
                <input readOnly autoFocus placeholder="" value={shareLink} className={styles.ModalInput} onChange={()=>{setIsCopied(false)}}/>
                <CopyToClipboard text={shareLink}
                  onCopy={() => setIsCopied(true)}>
                  <button className={styles.ShareLinkButton}>
                    { !isCopied ?
                      <FontAwesomeIcon className={styles.ShareLinkButtonIcon} icon={faClipboard} />
                    :
                      <FontAwesomeIcon style={{color:"#00BD70"}} className={styles.ShareLinkButtonIcon} icon={faCheck} />
                    }
                  </button>
                </CopyToClipboard>
              </div>

            </div>
          </Modal>
        </div>
    )
}

